import {
  DataOverride,
  LOCAL_STORAGE_DATA_OVERRIDE,
  LOCAL_STORAGE_IS_OFFLINE,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "utils";
import { StorageType, useGlobalStorage } from "context/GlobalStorage";

import Button from "components/Button";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
`;

const Title = styled.div`
  font-size: 15px;
  white-space: pre-wrap;
  text-align: left;
  color: ${(props) => props.theme.colors.text}AA;
`;

const ButtonContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-size: 15px;
  line-height: 1.4;
  white-space: pre-wrap;
  text-align: left;

  padding: 10px 0;

  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-left: 18px;
  }

  li {
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
  }
`;

const OfflineModePopUp = (props: { isOffline: boolean; popUpId: string }) => {
  const { isOffline, popUpId } = props;

  const { fns } = useGlobalStorage();

  const onEnd = () => {
    fns.setIsOfflineRaw(isOffline);
    fns.closePopUp(popUpId);
    window.location.reload(); // reload to handle data load / google login properly
  };

  const onUseLocalStorage = async (params: { keepCurrentData?: boolean }) => {
    // data here is from google drive
    if (params?.keepCurrentData) {
      // save active google drive data to local storage (overriding previously stored data)
      fns.saveData({ storageType: StorageType.localStorage });
    }

    saveToLocalStorage(LOCAL_STORAGE_IS_OFFLINE, true);
    onEnd();
  };

  const onUseGoogleDrive = async (params: { keepCurrentData?: boolean }) => {
    // data here is from local storage
    if (params?.keepCurrentData) {
      // offline mode doesn't have access to google drive -> handle data override on next login
      saveToLocalStorage(
        LOCAL_STORAGE_DATA_OVERRIDE,
        DataOverride.from_localStorage_to_googleDrive
      );
    } else {
      deleteFromLocalStorage(LOCAL_STORAGE_DATA_OVERRIDE);
    }

    saveToLocalStorage(LOCAL_STORAGE_IS_OFFLINE, false);
    onEnd();
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      {isOffline ? (
        <>
          <Title>Přepnout na local storage a:</Title>

          <Button onClick={() => onUseLocalStorage({ keepCurrentData: true })}>
            <ButtonContent>
              přepsat data v local storage aktuálními daty (z&nbsp;Google Drive)
            </ButtonContent>
          </Button>

          <Button onClick={() => onUseLocalStorage({ keepCurrentData: false })}>
            <ButtonContent>
              zahodit aktuální data (z&nbsp;Google Drive) a nahrát data dříve
              uložená v local storage
            </ButtonContent>
          </Button>
        </>
      ) : (
        <>
          <Title>Přepnout na Google Drive a:</Title>

          <Button onClick={() => onUseGoogleDrive({ keepCurrentData: true })}>
            <ButtonContent>
              přepsat data v Google Drive aktuálními daty (z&nbsp;local storage)
            </ButtonContent>
          </Button>

          <Button onClick={() => onUseGoogleDrive({ keepCurrentData: false })}>
            <ButtonContent>
              zahodit aktuální data (z&nbsp;local storage) a nahrát data dříve
              uložená v Google Drive
            </ButtonContent>
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default OfflineModePopUp;
