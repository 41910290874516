import { DataType, ExerciseType, MealType, SupplementType } from "types";

const SUPPLEMENTS_MORNING: SupplementType = {
  name: "Ráno",
  options: [
    { ingredients: ["Euthyrox", "1x Omega 3 / Krill oil", "vitamin K2+D3"] },
  ],
};

const SUPPLEMENTS_PRE_WORKOUT: SupplementType = {
  name: "Před tréninkem",
  options: [
    {
      ingredients: [
        "Nakopávač (YEEP PUMP No Caff)",
        "4g Citruline",
        "5g Glutamin",
        "4x HMB",
      ],
    },
  ],
};

const SUPPLEMENTS_POST_WORKOUT: SupplementType = {
  name: "Po tréninku",
  options: [{ ingredients: ["5g Creatine", "5g Glutamin", "4x HMB"] }],
};

const SUPPLEMENTS_LUNCH: SupplementType = {
  name: "Odpoledne",
  options: [{ ingredients: ["5g Creatine", "5g Glutamin", "4x HMB"] }],
};

const SUPPLEMENTS_NIGHT: SupplementType = {
  name: "Po večeři",
  options: [{ ingredients: ["3x ZMA MAX", "1x Omega 3 / Krill oil"] }],
};

const SUPPLEMENTS_WORKOUT_DAY: SupplementType[] = [
  SUPPLEMENTS_MORNING,
  SUPPLEMENTS_PRE_WORKOUT,
  SUPPLEMENTS_POST_WORKOUT,
  SUPPLEMENTS_NIGHT,
];

const SUPPLEMENTS_REST_DAY: SupplementType[] = [
  SUPPLEMENTS_MORNING,
  SUPPLEMENTS_LUNCH,
  SUPPLEMENTS_NIGHT,
];

const MEALS: MealType[] = [
  {
    name: "Snídaně",
    options: [
      {
        ingredients: [
          "4x vejce (míchané / omeleta)",
          "100g pečivo (žitný chleba / celozrnný toast)",
          "20g lučina linie",
          "libovolná zelenina",
        ],
      },
      {
        ingredients: [
          "(zapečené toasty)",
          "100g celozrnný toast",
          "75g Eidam 30%",
          "75g kuřecí šunka 92%+ masa",
          "20g lučina linie",
        ],
      },
      {
        ingredients: [
          "polotučný tvaroh 250g",
          "70g ovesné vločky",
          "150g ovoce mix (jahody, borůvky, maliny, kiwi, ...)",
          "20g kešu ořechy",
        ],
      },
    ],
  },

  {
    name: "Velké jídlo 1",
    options: [
      {
        ingredients: [
          "150g kuřecí/krůtí prsa / hovězí zadní / hovězí mleté (do 10% tuku na 100g)",
          "100g jasmínová rýže / semolinové těstoviny / NEBO 400g brambory",
          "libovolná zelenina",
          "olivový olej (na smažení nebo zakápnutí)",
          "   10g pro kuřecí",
          "   5g pro hovězí zadní",
          "   NIC pro hovězí mleté",
        ],
      },
    ],
  },

  {
    name: "Velké jídlo 2",
    options: [
      {
        ingredients: [
          "150g kuřecí/krůtí prsa / losos / pstruh / vepřová panenka",
          "100g jasmínová rýže / semolinové těstoviny / NEBO 400g brambory",
          "libovolná zelenina",
          "olivový olej (na smažení nebo zakápnutí)",
          "   10g pro kuřecí/krůtí nebo vepřovou panenku",
          "   jinak žádný",
        ],
      },
    ],
  },

  {
    name: "Před tréninkem",
    options: [
      {
        ingredients: [
          "125g Mozzarella nebo 200g Cottage PILOS",
          "100g žitný chleba / celozrnné pečivo",
          "20g kešu ořechy",
        ],
      },
      {
        ingredients: [
          "70g ovesné vločky / jáhlová kaše",
          "35g protein",
          "30g mandle / kešu / para ořechy nebo ořechové máslo",
        ],
      },
      {
        ingredients: [
          "200g Milbona protein puding",
          "(Erhmann puding co kupujeme má stejné hodnoty)",
          "100g celozrnné/žitné pečivo",
          "30g ořechy / ořechové máslo",
        ],
      },
    ],
  },

  {
    name: "Po tréninku",
    text: "Pokud není trénink, zařadit do jídelníčku libovolně",
    options: [
      {
        ingredients: [
          "35g protein",
          "50g rýžová kaše",
          "1ks ovoce (banán, hruška, jablko)",
        ],
      },
    ],
  },
];

const DEFAULT_PAUSE_MINUTES_HARD = 2.5;
const DEFAULT_PAUSE_MINUTES_SOFT = 1.2;

const CARDIO: ExerciseType = {
  name: "Kardio",
  isCardio: true,
  sets: [
    {
      durationMinutes: 20,
    },
  ],
};

const WORKOUT_PLAN: DataType = {
  workoutPlans: [
    {
      id: "workout-202412-1",
      text: "Tréninkový plán 2024 Prosinec",
      days: [
        {
          id: "monday",
          name: "Pondělí",
          meals: MEALS,
          supplements: SUPPLEMENTS_WORKOUT_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_HARD,
          exercisesText:
            "Silový trénink\nZáda, prsa, ramena, lýtka\nNecvičit do selhání, 1 opakování v rezervě.",
          exercises: [
            {
              name: "Přítahy (Hammer veslo)",
              text: undefined,
              sets: [
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
              ],
            },

            {
              name: "Shyby na široko",
              text: "Bez palců! Ideálně na rovné hrazdě (osa u multipressu).",
              sets: [
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
              ],
            },

            {
              name: "Benchpress",
              text: "Soustředit na spodní prsa, s malým obloukem.",
              sets: [
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
              ],
            },

            {
              name: "Tlaky na ramena v sedě",
              text: "S osou v kleci na dřepy, čelem k zrcadlu.",
              sets: [
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
                {
                  repCount: 6,
                },
              ],
            },

            {
              name: "Výpony ve stoje",
              text: "Lýtka, multipress",
              sets: [
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            CARDIO,
          ],
        },

        {
          id: "tuesday",
          name: "Úterý",
          meals: MEALS,
          supplements: SUPPLEMENTS_WORKOUT_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_HARD,
          exercisesText:
            "Silový trénink\nNohy, Hamstringy, Biceps, Triceps\nNecvičit do selhání, 1 opakování v rezervě.",
          exercises: [
            {
              name: "Dřep",
              text: "Nohy více od sebe, špičky ven, soustředit se na zadek.",
              sets: [
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
              ],
            },

            {
              name: "Zakopávání vleže",
              text: "Zadek se může trochu hýbat, neležet na břichu ale na rukách, aby nohy byly rovno k zádům při natažené pozici.",
              sets: [
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
                {
                  repCount: 5,
                },
              ],
            },

            {
              name: "Francouzský tlak s EZ",
              text: "Ruce vytažené nad hlavu, i v natažené pozici pod tlakem. Dole jít až k opěrátku lavičky.",
              sets: [
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
              ],
            },

            {
              name: "Biceps s velkou osou",
              text: "Nohy více od sebe, nebát se trochu nahodit.",
              sets: [
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
                {
                  repCount: 8,
                },
              ],
            },

            {
              name: "Břicho",
              text: "(nebylo blíže specifikované co cvičit)",
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            CARDIO,
          ],
        },

        {
          id: "wednesday",
          name: "Středa",
          meals: MEALS,
          supplements: SUPPLEMENTS_REST_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_HARD,
          exercises: [],
        },

        {
          id: "thursday",
          name: "Čtvrtek",
          meals: MEALS,
          supplements: SUPPLEMENTS_WORKOUT_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_SOFT,
          exercisesText:
            "Lehčí trénink, technicky\nZáda, prsa, ramena, lýtka\nAž do selhání, co nejvíce technicky a procítěně se zatínáním.",
          exercises: [
            {
              name: "Přítahy horní klady podhmat jednoruč",
              text: "Vzadu na stroji, žebra dolů, dívat se mírně do boku a nahoru.\nV horní pozici mírně vytočit aby se sval více protáhl",
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            {
              name: "Veslo v sedě",
              text: undefined,
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Tlaky s jednoručkami (/ multipress) horní prsa",
              text: "Nejezdit tak velký rozsah, držet sval neustále pod napětím.\nLokty cca 45° od těla.",
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            {
              name: "Peck-Deck",
              text: "(Předpokládám prsa)",
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Upažování střední delty kladka",
              text: "(neukazovali jsme si zatím - nevím co v plánu znamená '2x 12 1x 12+12')",
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Zapažování Peck-Deck zadní delky",
              text: "Více do předklonu, opřít se čelem. Soustředit se jen na pohyb rameny a vynechat trapézy, plavání.\n(nevím co v plánu znamená '2x 12 1x 12+12')",
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Výpony vsedě",
              text: "Lýtka, 4x 10+10 = superset?",
              sets: [
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            CARDIO,
          ],
        },

        {
          id: "friday",
          name: "Pátek",
          meals: MEALS,
          supplements: SUPPLEMENTS_WORKOUT_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_SOFT,
          exercisesText:
            "Lehčí trénink, technicky\nNohy, hamstringy, biceps, triceps\nAž do selhání, co nejvíce technicky a procítěně se zatínáním.",
          exercises: [
            {
              name: "Legpress",
              text: "Na tom kyvadlovém, záda do podložky, dívat se do stropu a soustředit na zadek.",
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            {
              name: "Předkopávání",
              text: "Pořádný rozsah, nahoře pořádně zatnout svaly.",
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Hipthrust",
              text: "Záda držet neustále rovně, ohýbat se jen v kyčlích, nebrat to přes bedra, ale zadkem.",
              sets: [
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 12,
                },
              ],
            },

            {
              name: "Biceps spodní kladka / EZ scottova lavice",
              text: undefined,
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            {
              name: "Triceps stahování kladky",
              text: undefined,
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            {
              name: "Břicho",
              text: "(nebylo blíže specifikované co cvičit)",
              sets: [
                {
                  repCount: 15,
                },
                {
                  repCount: 12,
                },
                {
                  repCount: 10,
                },
                {
                  repCount: 10,
                },
              ],
            },

            CARDIO,
          ],
        },

        {
          id: "saturday",
          name: "Sobota",
          meals: MEALS,
          supplements: SUPPLEMENTS_REST_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_HARD,
          exercises: [],
        },

        {
          id: "sunday",
          name: "Neděle",
          meals: MEALS,
          supplements: SUPPLEMENTS_REST_DAY,
          defaultPauseBetweenSetsMinutes: DEFAULT_PAUSE_MINUTES_HARD,
          exercises: [],
        },
      ],
    },
  ],
};

export default WORKOUT_PLAN;
