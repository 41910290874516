import LinkBox from "components/LinkBox";
import WeightEditor from "components/WeightEditor";
import { WorkoutPlanType } from "types";
import { useGlobalStorage } from "context/GlobalStorage";

export enum WeightTypeEnum {
  "start" = "start",
  "end" = "end",
}

const WeightLink = (props: {
  dataWorkoutPlan: WorkoutPlanType;
  weightType: WeightTypeEnum;
}) => {
  const { dataWorkoutPlan, weightType } = props;

  const { fns } = useGlobalStorage();

  const weight =
    weightType === WeightTypeEnum.start
      ? dataWorkoutPlan.weight?.startKg
      : dataWorkoutPlan.weight?.endKg;

  const label = weight
    ? `${weight} kg`
    : weightType === WeightTypeEnum.start
    ? "Vložit počáteční váhu"
    : "Vložit konečnou váhu";

  const onClick = () => {
    const weightPopUpId = "weight-popup";

    fns.showPopUp({
      id: weightPopUpId,
      title:
        weightType === WeightTypeEnum.start ? "Počáteční váha" : "Konečná váha",
      noPadding: true,
      content: (
        <WeightEditor
          weight={weight}
          onSave={(newWeightValue) => {
            fns.updateWeight({
              workoutId: dataWorkoutPlan.id,
              newWeight: {
                ...(dataWorkoutPlan.weight || {}),
                ...(weightType === WeightTypeEnum.start
                  ? { startKg: newWeightValue }
                  : { endKg: newWeightValue }),
              },
            });
            fns.closePopUp(weightPopUpId);
          }}
        />
      ),
    });
  };

  return (
    <LinkBox
      label={label}
      progressData={{
        weight: weight ? 1 : 0,
      }}
      onClick={onClick}
    />
  );
};

export default WeightLink;
