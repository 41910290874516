/* eslint-disable react-hooks/exhaustive-deps */

import { FaGoogleDrive } from "react-icons/fa";
import LayoutCentered from "layouts/LayoutCentered";
import { MdFlight } from "react-icons/md";
import styled from "styled-components";
import { useEffect } from "react";
import { useGlobalStorage } from "context/GlobalStorage";

const Button = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  --size: 200px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  border-radius: calc(var(--size) / 2);
  border: 2px solid ${(props) => props.theme.colors.main};
  background: ${(props) => props.theme.colors.main}04;

  svg {
    font-size: 50px;
    color: ${(props) => props.theme.colors.text}DD;
  }
`;

const LoginScreen = () => {
  const { fns, isSignedIn, isOffline } = useGlobalStorage();

  useEffect(() => {
    if (!isSignedIn && isOffline) {
      fns.signIn();
    }
  }, [isOffline]);

  return (
    <LayoutCentered>
      <Button onClick={() => fns.signIn()}>
        {isOffline ? <MdFlight /> : <FaGoogleDrive />}
      </Button>
    </LayoutCentered>
  );
};

export default LoginScreen;
