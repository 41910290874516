import styled from "styled-components";

const Wrapper = styled.div<{ $diameter: string; $size: string }>`
  .loader {
    --diameter: ${(props) => props.$diameter};
    --size: ${(props) => props.$size};

    width: var(--diameter);
    aspect-ratio: 1;

    --_c: ${(props) =>
      `no-repeat radial-gradient(farthest-side, ${props.theme.colors.main} 92%, #0000)`};

    background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
    background-size: var(--size) var(--size);
    animation: l7 1s infinite;
  }
  @keyframes l7 {
    to {
      transform: rotate(0.5turn);
    }
  }
`;

const LoadingSpinner = (props: { diameter?: string; size?: string }) => {
  const { diameter = "200px", size = "5px" } = props;

  return (
    <Wrapper $diameter={diameter} $size={size}>
      <div className="loader"></div>
    </Wrapper>
  );
};

export default LoadingSpinner;
