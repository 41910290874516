import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding: 10px 14px;

  padding-top: 60px; // space for app header
`;

const LayoutMain = (props?: { children?: any }) => {
  return (
    <Wrapper>
      <Content>{props?.children}</Content>
    </Wrapper>
  );
};

export default LayoutMain;
