import { IconType } from "react-icons";
import { IoMdInformationCircleOutline } from "react-icons/io";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.text}11;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;

  svg {
    color: ${(props) => props.theme.colors.text}44;
    font-size: 20px;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  color: ${(props) => props.theme.colors.text}AA;

  padding: 10px;
  font-size: 16px;
  line-height: 140%;
  white-space: pre-wrap;
`;

const InfoBox = (props: { icon?: IconType; text?: string; children?: any }) => {
  const { icon: CustomIcon, text } = props;

  return (
    <Wrapper>
      <Icon>
        {CustomIcon ? <CustomIcon /> : <IoMdInformationCircleOutline />}
      </Icon>
      <Content>
        {text}
        {props.children}
      </Content>
    </Wrapper>
  );
};

export default InfoBox;
