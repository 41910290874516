import { FaCheck } from "react-icons/fa6";
import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: ${(props) => props.theme.colors.backgroundDark}44;
  padding: 6px 6px;
`;

const Checkbox = styled.div`
  --size: 24px;
  margin: 4px;

  position: relative;
  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${(props) => props.theme.colors.text}44;
  border-radius: 4px;
`;

const Icon = styled.div`
  position: absolute;
  top: 2px;

  transition: all 0.2s ease;
  color: ${(props) => props.theme.colors.main};

  svg {
    font-size: 18px;
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.main}AA;
  font-family: inherit;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
`;

const BooleanInput = (props: {
  label?: string;
  value: boolean;
  onChange: (v: boolean) => void;
  noFlex?: boolean;
}) => {
  const { label, value, onChange, noFlex } = props;

  return (
    <Wrapper
      onClick={() => onChange(!value)}
      style={noFlex ? { flex: 0 } : undefined}
    >
      <Checkbox>
        {value ? (
          <Icon>
            <FaCheck />
          </Icon>
        ) : null}
      </Checkbox>

      {label ? <Label> {label}</Label> : null}
    </Wrapper>
  );
};

export default BooleanInput;
