import LayoutMain from "layouts/LayoutMain";
import NoDataScreen from "components/NoDataScreen";
import PageHeader from "components/PageHeader";
import Supplement from "components/Supplement";
import styled from "styled-components";
import { useDay } from "hooks/useDay";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Supplements = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;

  padding-bottom: 20vh;
`;

const DaySupplementsPage = () => {
  const { workoutId, dayId } = useParams<{
    workoutId: string;
    dayId: string;
  }>();
  const day = useDay({ workoutId, dayId });

  if (!day) {
    return <NoDataScreen />;
  }

  return (
    <LayoutMain>
      <PageHeader title={`Suplementy`} subtitleTop={day.name} />

      <Wrapper>
        <Supplements>
          {day.supplements?.map((supplement, i) => (
            <Supplement
              key={i}
              day={day}
              workoutId={workoutId}
              supplementIndex={i}
              supplement={supplement}
            />
          ))}
        </Supplements>
      </Wrapper>
    </LayoutMain>
  );
};

export default DaySupplementsPage;
