import { PopUpType, PopUpTypeEnum } from "types";
import styled, { css } from "styled-components";

import { IoCloseSharp } from "react-icons/io5";
import { useGlobalStorage } from "context/GlobalStorage";

const Wrapper = styled.div<{ $index: number; $isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.layout.zIndexPopUps - props.$index};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;

  ${(props) =>
    props.$isActive
      ? css``
      : css`
          opacity: 0.4;
          pointer-events: none;
        `}
`;

const Box = styled.div`
  position: relative;
  flex: 1;
  max-width: min(100vw - 20px, 500px);
  max-height: min(100vh - 20px, 800px);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: ${(props) => props.theme.colors.background};
  border-radius: 4px;
  box-shadow: 0 0 20px 10px ${(props) => props.theme.colors.backgroundDark}88;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Title = styled.div<{ $type: PopUpTypeEnum }>`
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  padding: 13px;
  padding-top: 15px;

  color: ${(props) =>
    props.$type === PopUpTypeEnum.error
      ? props.theme.colors.error
      : props.theme.colors.text};

  opacity: 0.8;
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.text}44;
  }
`;

const Content = styled.div<{ $noPadding?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10px;
  padding-bottom: 40px;

  text-align: center;

  overflow: hidden;

  ${(props) =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
`;

const PopUp = (props: {
  popUp: PopUpType;
  index: number;
  isActive: boolean;
}) => {
  const { popUp, index, isActive } = props;

  const { fns } = useGlobalStorage();

  return (
    <Wrapper $index={index} $isActive={isActive}>
      <Box>
        <Header>
          {popUp.title ? (
            <Title $type={popUp.type || PopUpTypeEnum.info}>
              {popUp.title}
            </Title>
          ) : null}

          <CloseButton onClick={() => fns.closePopUp(popUp.id)}>
            <IoCloseSharp />
          </CloseButton>
        </Header>

        <Content $noPadding={popUp.noPadding}>{popUp.content}</Content>
      </Box>
    </Wrapper>
  );
};

export default PopUp;
