import { Route, Routes } from "react-router-dom";

import DataJsonEditor from "./DataJsonEditor";
import DataPreview from "./DataPreview";
import DayMealsPage from "./DayMealsPage";
import DayPage from "./DayPage";
import DaySupplementsPage from "./DaySupplementsPage";
import DayWorkoutPage from "./DayWorkoutPage";
import HomePage from "./HomePage";
import SettingsPage from "./SettingsPage";

export const PageSlugs = {
  homepage: "/",
  settings: "/settings",
  dataJsonEditor: "/data-json-editor",
  dataPreview: "/:workoutId/preview",
  day: "/:workoutId/:dayId",
  dayMeals: "/:workoutId/:dayId/meals",
  daySupplements: "/:workoutId/:dayId/supplements",
  dayWorkout: "/:workoutId/:dayId/workout",
};

const Pages = () => {
  return (
    <Routes>
      <Route path={PageSlugs.homepage} element={<HomePage />} />
      <Route path={PageSlugs.settings} element={<SettingsPage />} />
      <Route path={PageSlugs.dataPreview} element={<DataPreview />} />
      <Route path={PageSlugs.dataJsonEditor} element={<DataJsonEditor />} />
      <Route path={PageSlugs.day} element={<DayPage />} />
      <Route path={PageSlugs.dayMeals} element={<DayMealsPage />} />
      <Route path={PageSlugs.daySupplements} element={<DaySupplementsPage />} />
      <Route path={PageSlugs.dayWorkout} element={<DayWorkoutPage />} />
    </Routes>
  );
};

export default Pages;
