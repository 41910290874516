import styled, { css } from "styled-components";

import { FaWeightScale } from "react-icons/fa6";
import { GiNightSleep } from "react-icons/gi";
import { IconType } from "react-icons";
import { IoFitness } from "react-icons/io5";
import { Link } from "react-router-dom";
import ProgressCircle from "components/ProgressCircle";
import { RiRestaurant2Line } from "react-icons/ri";
import { TbPill } from "react-icons/tb";

const StyledLink = styled(Link)<{ $isCompleted: boolean }>`
  flex: 1;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.text}DD;
  background: ${(props) => props.theme.colors.text}18;
  text-decoration: none;
  padding: 16px 16px;

  ${(props) =>
    props.$isCompleted
      ? css`
          background: ${(props) => props.theme.colors.text}0A;
        `
      : css`
          background: ${(props) => props.theme.colors.text}18;
        `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Name = styled.div`
  font-size: 17px;
`;

const Progress = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const ProgressIcon = styled.div<{ $isCompleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$isCompleted
      ? css`
          color: ${(props) => props.theme.colors.main}BB;
        `
      : css`
          color: ${(props) => props.theme.colors.text}44;
        `}

  svg {
    font-size: 16px;
  }
`;

const LinkBox = (props: {
  label: string;
  to?: string;
  onClick?: () => void;
  progressData?: {
    meals?: number;
    supplements?: number;
    workout?: number;
    restDay?: number;
    weight?: number;
  };
}) => {
  const { label, onClick, to, progressData = {} } = props;

  const progressCount = Object.values(progressData).filter(
    (v) => v !== undefined
  ).length;
  const progressTotal =
    Object.values(progressData).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ) / progressCount;

  const renderProgressIcon = (params: {
    progressId: string;
    progress: number;
  }) => {
    const { progressId, progress } = params;

    let Icon: IconType;
    let isFaded: boolean;

    switch (progressId) {
      case "meals":
        Icon = RiRestaurant2Line;
        break;
      case "supplements":
        Icon = TbPill;
        break;
      case "workout":
        Icon = IoFitness;
        break;
      case "restDay":
        Icon = GiNightSleep;
        isFaded = true;
        break;
      case "weight":
        Icon = FaWeightScale;
        break;
    }

    return (
      <div key={progressId} style={isFaded ? { opacity: 0.5 } : undefined}>
        <ProgressCircle
          progress={progress}
          diameter={30}
          strokeWidth={2}
          textFontSize="9px"
          textFontWeight="400"
        >
          <ProgressIcon $isCompleted={progress === 1}>
            <Icon />
          </ProgressIcon>
        </ProgressCircle>
      </div>
    );
  };

  return (
    <StyledLink
      to={to}
      onClick={onClick}
      $isCompleted={progressCount && progressTotal === 1}
    >
      <Wrapper>
        <Name>{label}</Name>

        <Progress>
          {Object.entries(progressData).map(([progressId, p]) =>
            renderProgressIcon({
              progressId: progressId,
              progress: p,
            })
          )}
        </Progress>
      </Wrapper>
    </StyledLink>
  );
};

export default LinkBox;
