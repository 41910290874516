import { gapi } from "gapi-script";

const CLIENT_ID =
  "1095956354734-fhpqfktad1l2i2ce2b64n4j0d3v5c4i2.apps.googleusercontent.com"; // Replace with your OAuth 2.0 Client ID
const API_SCOPE = "https://www.googleapis.com/auth/drive.file";

export const googleInitApi = () => {
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: CLIENT_ID,
      scope: API_SCOPE,
    });
  });
};

export const googleSignIn = async () => {
  const authInstance = gapi.auth2.getAuthInstance();
  if (!authInstance.isSignedIn.get()) {
    await authInstance.signIn();
  }
  return authInstance.currentUser.get().getAuthResponse().access_token;
};

export const googleSignOut = () => {
  gapi.auth2.getAuthInstance().signOut();
};

export const googleRefreshToken = async () => {
  try {
    const authInstance = gapi.auth2.getAuthInstance();
    const user = authInstance.currentUser.get();
    const refreshedToken = await user.reloadAuthResponse(); // Refresh token silently
    return { newToken: refreshedToken.access_token };
  } catch (error) {
    console.error("Error refreshing token:", error);
    return { error };
  }
};

export const getValidToken = async () => {
  const authInstance = gapi.auth2.getAuthInstance();
  const user = authInstance.currentUser.get();
  const authResponse = user.getAuthResponse();

  const isExpired = googleTokenIsExpired(authResponse);
  if (isExpired) {
    // Refresh token
    const { newToken, error } = await googleRefreshToken();
    if (newToken) {
      console.info("Access token refreshed");
      return newToken;
    } else {
      console.error("Failed to refresh access token:", error);
      throw new Error("Token refresh failed");
    }
  }

  // Return the existing token if it's still valid
  return authResponse.access_token;
};

export const googleTokenIsExpired = (authResponse) => {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const tokenExpirationTime = authResponse.expires_at / 1000; // Token expiration in seconds
  const isExpired = tokenExpirationTime - currentTime <= 60; // Refresh if less than 1 minute left
  return isExpired;
};
