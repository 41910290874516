import axios from "axios";

// Cloudflare Worker URL (for reference, and used in the fetch request)
const cloudflareWorkerUrl = "https://fitness-tracker.jan-hamernik.workers.dev"; // TODO - TO .ENV

export const fetchPublicData = async (props: { fileId: string }) => {
  const { fileId } = props;

  try {
    const url = `${cloudflareWorkerUrl}?publicFileId=${fileId}`;

    // Send GET request to the Cloudflare Worker to download the file
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
    });

    return response.data; // Assuming your worker returns the file content as JSON
  } catch (error) {
    console.error("Error downloading file:", error.message);
    throw new Error("Nepodařilo se stáhnout data.");
  }
};
