import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LayoutCentered(props?: { children?: any }) {
  return <Wrapper>{props?.children}</Wrapper>;
}

export default LayoutCentered;
