import axios from "axios"; // Axios to send requests to Cloudflare Worker

// Cloudflare Worker URL (change to your Worker endpoint)
const cloudflareWorkerUrl = "https://fitness-tracker.jan-hamernik.workers.dev"; // TODO - MOVE TO ENV

export const uploadJsonFile = async (accessToken, fileName, jsonData) => {
  try {
    const response = await axios.post(
      `${cloudflareWorkerUrl}/upload`,
      {
        accessToken, // Send the access token
        fileName, // Send the file name
        jsonData, // Send the JSON data
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token for worker authorization
          "Content-Type": "application/json", // Set the content type to JSON
        },
      }
    );

    return response.data; // Assuming your worker returns JSON data
  } catch (error) {
    console.error("Error uploading file:", error.message);
    throw new Error("Failed to upload file");
  }
};

export const downloadJsonFile = async (accessToken, fileId) => {
  try {
    // Send GET request to the Cloudflare Worker to download the file
    const response = await axios.get(
      `${cloudflareWorkerUrl}?fileId=${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token for worker authorization
          "Content-Type": "application/json", // Set the content type to JSON
        },
      }
    );

    return response.data; // Assuming your worker returns the file content as JSON
  } catch (error) {
    console.error("Error downloading file:", error.message);
    throw new Error("Failed to download file");
  }
};

export const updateJsonFile = async (accessToken, fileId, jsonData) => {
  try {
    const response = await axios.patch(
      `${cloudflareWorkerUrl}/update`, // Ensure this is the correct endpoint for your worker
      {
        accessToken, // Send the access token as part of the body
        fileId, // Send the fileId
        jsonData, // Send the updated JSON data
      },
      {
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          Authorization: `Bearer ${accessToken}`, // Include the access token for authorization (only if needed by your worker)
        },
      }
    );
    return response.data; // Assuming the worker returns JSON data
  } catch (error) {
    console.error("Error updating file:", error);
    throw new Error("Failed to update file");
  }
};
