import { DayType } from "types";

export const getSupplementsProgress = (day: DayType) => {
  let progress = 0;
  let total = 0;

  day.supplements?.forEach((supplement) => {
    const isCompleted =
      supplement.customOption?.isCompleted ||
      !!supplement.options?.find(
        (supplementOption) => supplementOption.isCompleted
      );
    if (isCompleted) {
      progress += 1;
    }
    total += 1;
  });

  return total ? Math.round((progress / total) * 100) / 100 : 0;
};
