import styled, { css } from "styled-components";

import { IconType } from "react-icons";

const Wrapper = styled.button<{
  $small?: boolean;
  $faded?: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  gap: 8px;

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;

  color: ${(props) => props.theme.colors.main}AA;
  border: 1px solid ${(props) => props.theme.colors.main}33;

  border-radius: 4px;
  padding: 10px 14px;

  &:hover {
    background: ${(props) => props.theme.colors.main}06;
  }

  svg {
    font-size: 15px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}

  ${(props) =>
    props.$small &&
    css`
      font-size: 14px;
    `}

${(props) =>
    props.$faded &&
    css`
      font-weight: 300;
      color: ${(props) => props.theme.colors.text}77;
      border-color: ${(props) => props.theme.colors.text}22;
    `}
`;

const Button = (props: {
  label?: string;
  icon?: IconType;
  small?: boolean;
  faded?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: any;
}) => {
  const { label, icon: Icon, disabled, small, faded, onClick } = props;

  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled}
      $small={small}
      $faded={faded}
    >
      {Icon ? <Icon /> : null}
      {label ?? null}
      {props.children}
    </Wrapper>
  );
};

export default Button;
