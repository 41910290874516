/* eslint-disable react-hooks/exhaustive-deps */

import styled, { css } from "styled-components";
import { useEffect, useRef } from "react";

import InputWrapper from "../InputWrapper";

const Wrapper = styled.div<{ height?: any; $noTextWrap?: boolean }>`
  flex: 1;
  display: flex;
  align-items: stretch;
  background: ${(props) => props.theme.colors.backgroundDark}44;
  border-radius: 4px;

  textarea,
  input {
    flex: 1;
    box-sizing: border-box;
    outline: none;
    border: none;
    resize: none;
    background: none;
    overflow: hidden;
    min-height: 46px;
    padding: 10px 15px;

    color: ${(props) => props.theme.colors.main}AA;

    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.3;
    text-align: left;

    ${(props) =>
      props.height
        ? css`
            height: ${props.height};
          `
        : css``}

    ${(props) =>
      props.$noTextWrap
        ? css`
            overflow: auto;
            white-space: pre;
          `
        : css``}
  }
`;

const TextInput = (props: {
  label?: string;
  value: string;
  placeholder?: string;
  textarea?: boolean;
  onChange: (v: string) => void;
  height?: any;
  noTextWrap?: boolean;
}) => {
  const { label, value, placeholder, textarea, height, noTextWrap, onChange } =
    props;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateHeight = () => {
    if (textAreaRef.current && value !== undefined) {
      const textArea = textAreaRef.current;
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (height) {
      return;
    }
    if (textarea) {
      updateHeight();
    }
  }, [value, textarea]);

  const handleInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange(e.target.value);
  };

  return (
    <InputWrapper label={label}>
      <Wrapper height={height} $noTextWrap={noTextWrap}>
        {textarea ? (
          <textarea
            ref={textAreaRef}
            value={value || ""}
            onChange={handleInput}
            placeholder={placeholder}
          />
        ) : (
          <input
            value={value || ""}
            onChange={handleInput}
            placeholder={placeholder}
          />
        )}
      </Wrapper>
    </InputWrapper>
  );
};

export default TextInput;
