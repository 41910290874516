import WeightLink, { WeightTypeEnum } from "components/WeightLink";

import DayLink from "components/DayLink";
import LinkBox from "components/LinkBox";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import { WorkoutPlanType } from "types";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;

  margin-bottom: 40px;
`;

const WorkoutPlan = (props: { dataWorkoutPlan: WorkoutPlanType }) => {
  const { dataWorkoutPlan } = props;

  return (
    <Wrapper>
      <PageHeader
        title={`${dataWorkoutPlan.dates?.start}  -  ${dataWorkoutPlan.dates?.end}`}
        text={dataWorkoutPlan?.text}
      />

      <WeightLink
        dataWorkoutPlan={dataWorkoutPlan}
        weightType={WeightTypeEnum.start}
      />
      {dataWorkoutPlan?.days?.map((day, i) => {
        return <DayLink key={i} workoutId={dataWorkoutPlan.id} day={day} />;
      })}
      <WeightLink
        dataWorkoutPlan={dataWorkoutPlan}
        weightType={WeightTypeEnum.end}
      />
      <LinkBox
        label={"Přehled"}
        to={PageSlugs.dataPreview.replace(":workoutId", dataWorkoutPlan.id)}
      />
    </Wrapper>
  );
};

export default WorkoutPlan;
