import { DayType, ExerciseSetType, ExerciseType } from "types";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import styled, { css } from "styled-components";

import BooleanInput from "components/Inputs/BooleanInput";
import Button from "components/Button";
import { FaRegTrashAlt } from "react-icons/fa";
import NumberInput from "components/Inputs/NumberInput";
import { RiSaveFill } from "react-icons/ri";
import TextInput from "components/Inputs/TextInput";
import { useGlobalStorage } from "context/GlobalStorage";
import { useState } from "react";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 12px;
  padding-top: 20px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const SectionName = styled.div`
  text-align: center;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;

  margin-top: 40px;
  color: ${(props) => props.theme.colors.text}AA;
`;

const Sets = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
`;

const Set = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SetLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SetRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.text}0A;

  padding: 20px 10px;
`;

const SetRightRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const SetIconBtn = styled.div<{ disabled?: boolean }>`
  position: relative;
  cursor: pointer;

  --size: 35px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${(props) => props.theme.colors.text}20;
  border-radius: 50%;

  svg {
    font-size: 14px;
    color: ${(props) => props.theme.colors.text}99;
  }

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          cursor: default;
          opacity: 0.4;
        `
      : css``}
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 16px 12px;
`;

const ExerciseEditor = (props: {
  workoutId: string;
  day: DayType;
  exercise: ExerciseType;
  exerciseIndex: number;
  onSave?: () => void;
}) => {
  const { workoutId, day, exerciseIndex, onSave } = props;

  const [exercise, setExercise] = useState<ExerciseType>({ ...props.exercise });

  const { fns } = useGlobalStorage();

  //
  // DATA
  //
  const saveChanges = () => {
    fns.updateDay({
      workoutId,
      dayId: day.id,
      newDay: {
        ...day,
        exercises: day.exercises.map((e, i) =>
          i === exerciseIndex ? exercise : e
        ),
      },
    });

    onSave?.();
  };

  //
  // UTILS
  //
  const updateExercise = (newExercise: Partial<ExerciseType>) => {
    setExercise((prevState) => {
      return { ...prevState, ...newExercise };
    });
  };

  const updateSet = (newSet: Partial<ExerciseSetType>, index: number) => {
    const newExercise = {
      ...exercise,
      sets: exercise.sets.map((s, i) =>
        i === index ? { ...s, ...newSet } : s
      ),
    };
    updateExercise(newExercise);
  };

  const moveSet = (index, direction) => {
    const newSets = [...exercise.sets];

    // Calculate the new index
    const newIndex = index + direction;

    // Edge case: If the new index is out of bounds, do nothing
    if (newIndex < 0 || newIndex >= newSets.length) {
      return newSets; // No changes
    }

    // Swap the items
    const temp = newSets[index];
    newSets[index] = newSets[newIndex];
    newSets[newIndex] = temp;

    const newExercise = {
      ...exercise,
      sets: newSets,
    };
    updateExercise(newExercise);
  };

  const deleteSet = (index) => {
    const newExercise = {
      ...exercise,
      sets: exercise.sets.filter((s, i) => i !== index),
    };
    updateExercise(newExercise);
  };

  const addSet = () => {
    const newSet: ExerciseSetType = {
      value: undefined,
      repCount: !exercise.isCardio ? 10 : undefined,
      durationMinutes: exercise.isCardio ? 10 : undefined,
      isCompleted: undefined,
    };
    updateExercise({
      ...exercise,
      sets: [...exercise.sets, newSet],
    });
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>
        <TextInput
          label={`Název`}
          value={exercise.name}
          onChange={(v) => updateExercise({ name: v })}
          textarea
        />

        <TextInput
          label={`Popis`}
          value={exercise.text}
          onChange={(v) => updateExercise({ text: v })}
          textarea
        />

        <SectionName>Typ cviku</SectionName>
        <BooleanInput
          label={`Kardio`}
          value={exercise.isCardio}
          onChange={(v) => updateExercise({ isCardio: v })}
        />

        <SectionName>Série</SectionName>
        <NumberInput
          label={`Pauza (min)`}
          value={
            exercise.pauseBetweenSetsMinutes ??
            day.defaultPauseBetweenSetsMinutes
          }
          onChange={(v) => updateExercise({ pauseBetweenSetsMinutes: v })}
        />
        <NumberInput
          label={`Počet sérií`}
          value={exercise.sets.length}
          step="1"
          noManualInput
          onIncrementUp={() => addSet()}
          onIncrementDown={() => deleteSet(exercise.sets.length - 1)}
        />

        <Sets>
          {exercise.sets?.map((set, i) => {
            return (
              <Set key={i}>
                <SetLeft>
                  <SetIconBtn onClick={() => moveSet(i, -1)}>
                    <FaArrowUp />
                  </SetIconBtn>

                  <SetIconBtn onClick={() => deleteSet(i)}>
                    <FaRegTrashAlt />
                  </SetIconBtn>

                  <SetIconBtn onClick={() => moveSet(i, 1)}>
                    <FaArrowDown />
                  </SetIconBtn>
                </SetLeft>

                <SetRight>
                  <SetRightRow>
                    {exercise.isCardio ? (
                      <NumberInput
                        label={`Trvání (min)`}
                        value={set.durationMinutes}
                        onChange={(v) => updateSet({ durationMinutes: v }, i)}
                      />
                    ) : (
                      <NumberInput
                        label={`Počet opakování`}
                        step="1"
                        increment={1}
                        value={set.repCount}
                        onChange={(v) => updateSet({ repCount: v }, i)}
                      />
                    )}

                    <BooleanInput
                      value={set.isCompleted}
                      onChange={(v) => updateSet({ isCompleted: v }, i)}
                      noFlex
                    />
                  </SetRightRow>

                  <TextInput
                    label={`Hodnota`}
                    value={set.value}
                    onChange={(v) => updateSet({ value: v }, i)}
                  />
                </SetRight>
              </Set>
            );
          })}
        </Sets>
      </Scroll>

      <Footer>
        <Button icon={RiSaveFill} label="Uložit" onClick={saveChanges} />
      </Footer>
    </Wrapper>
  );
};

export default ExerciseEditor;
