import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
}

/* Scrollbar styling */
* {
  --scrollbar-color: #888;

    scrollbar-width: thin; /* Firefox: Set scrollbar width to thin */
    scrollbar-color: var(--scrollbar-color) #2E3236; /* Firefox: Set thumb and track colors */
}

*::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
}

*::-webkit-scrollbar-track {
    background: #2E3236; /* Track color */
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color); /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #2E3236; /* Optional: adds padding inside the scrollbar */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #00ccaa; /* Hover color for the thumb */
}

`;

export const theme = {
  colors: {
    background: "#2E3236",
    backgroundDark: "#121415",
    text: "#ffffff",
    main: "#33ffe0",
    mainDark: "#205c53",
    mainDark2: "#264a45",
    todo: "#ff3352",
    warning: "#ffbb33",
    error: "#ff3352",
  },
  breakpoints: {
    L: "1200px",
    M: "900px",
    S: "640px",
    XS: "560px",
  },
  layout: {
    maxWidth: "960px",
    zIndexHeader: 100,
    zIndexTimer: 800,
    zIndexPopUpsOverlay: 900,
    zIndexPopUps: 1000,
  },
};
