/* eslint-disable react-hooks/exhaustive-deps */

import {
  LOCAL_STORAGE_IS_OFFLINE,
  googleInitApi,
  loadFromLocalStorage,
} from "utils";

import AppHeader from "components/AppHeader";
import LoadingScreen from "components/LoadingScreen";
import LoginScreen from "components/LoginScreen";
import Pages from "pages";
import Timer from "components/Timer";
import { useAutoSaveReload } from "hooks/useAutoSaveReload";
import { useEffect } from "react";
import { useGlobalStorage } from "context/GlobalStorage";

const AppPrivate = () => {
  const { fns, isSignedIn, isLoading, isOffline } = useGlobalStorage();

  useAutoSaveReload();

  useEffect(() => {
    const asyncFunc = async () => {
      await googleInitApi();

      const storedIsOffline: boolean = loadFromLocalStorage(
        LOCAL_STORAGE_IS_OFFLINE
      );

      fns.setIsOfflineRaw(storedIsOffline);
    };

    asyncFunc();
  }, []);

  //
  // RENDER
  //
  return (
    <>
      {!isSignedIn ? (
        isOffline === undefined ? (
          <LoadingScreen />
        ) : (
          <LoginScreen />
        )
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Pages />
          <AppHeader />
          <Timer />
        </>
      )}
    </>
  );
};

export default AppPrivate;
