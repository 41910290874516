import { StorageType, useGlobalStorage } from "context/GlobalStorage";

import Button from "components/Button";
import { FaGoogleDrive } from "react-icons/fa";
import LayoutMain from "layouts/LayoutMain";
import { MdFlight } from "react-icons/md";
import TextInput from "components/Inputs/TextInput";
import styled from "styled-components";
import { useState } from "react";

const SECTIONS_HEIGHT = 140;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Sections = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 6%;

  height: ${SECTIONS_HEIGHT}px;
  min-height: ${SECTIONS_HEIGHT}px;
  max-height: ${SECTIONS_HEIGHT}px;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  margin-bottom: auto;
`;

const SectionName = styled.div`
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text}AA;
`;

const Buttons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  button {
    flex: 1;
  }
`;

const DataJsonEditor = () => {
  const { data, fns } = useGlobalStorage();

  const [dataString, setDataString] = useState(JSON.stringify(data, null, 2));

  const getEditedData = () => {
    try {
      const parsedData = JSON.parse(dataString);

      return parsedData;
    } catch (error) {
      fns.handleError({
        title: "Chyba v JSON datech",
        error,
      });
    }
  };

  const onLoadFromGoogleDrive = () => {
    const loadedData = fns.loadData({ storageType: StorageType.googleDrive });
    setDataString(JSON.stringify(loadedData, null, 2));
  };
  const onSaveToGoogleDrive = () => {
    const editedData = getEditedData();
    fns.saveData({ editedData, storageType: StorageType.googleDrive });
  };

  const onLoadFromLocalStorage = () => {
    const loadedData = fns.loadData({ storageType: StorageType.localStorage });
    setDataString(JSON.stringify(loadedData, null, 2));
  };
  const onSaveToLocalStorage = () => {
    const editedData = getEditedData();
    fns.saveData({ editedData, storageType: StorageType.localStorage });
  };

  return (
    <LayoutMain>
      <Wrapper>
        <Sections>
          <Section>
            <SectionName>Google Drive</SectionName>

            <Buttons>
              <Button
                icon={FaGoogleDrive}
                label={`Load`}
                onClick={onLoadFromGoogleDrive}
                small
              />
              <Button
                icon={FaGoogleDrive}
                label={`Save`}
                onClick={onSaveToGoogleDrive}
                small
              />
            </Buttons>
          </Section>

          <Section>
            <SectionName>Local Storage</SectionName>

            <Buttons>
              <Button
                icon={MdFlight}
                label={`Load`}
                onClick={onLoadFromLocalStorage}
                small
              />
              <Button
                icon={MdFlight}
                label={`Save`}
                onClick={onSaveToLocalStorage}
                small
              />
            </Buttons>
          </Section>
        </Sections>

        <Section>
          <SectionName>JSON Data</SectionName>
          <TextInput
            value={dataString}
            onChange={(v) => setDataString(v)}
            textarea
            height={`calc(100vh - ${SECTIONS_HEIGHT + 100}px)`}
            noTextWrap
          />
        </Section>
      </Wrapper>
    </LayoutMain>
  );
};

export default DataJsonEditor;
