import styled, { css } from "styled-components";

import AppMenu from "components/AppMenu";
import { FaGoogleDrive } from "react-icons/fa";
import { GoHomeFill } from "react-icons/go";
import { GrMenu } from "react-icons/gr";
import { Link } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { MdFlight } from "react-icons/md";
import { PageSlugs } from "pages";
import { useGlobalStorage } from "context/GlobalStorage";
import { useState } from "react";

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
`;

const HeaderContent = styled.div`
  flex: 1;
  max-width: ${(props) => props.theme.layout.maxWidth};

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  padding: 2px 4px;
`;

const HeaderGradient = styled.div`
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  height: 30px;
  background: ${(props) =>
    `linear-gradient(to bottom, ${props.theme.colors.background}66, ${props.theme.colors.background}00)`};
`;

const Icon = styled.div<{
  $isHighlighted?: boolean;
  $isFaded?: boolean;
  $isWarning?: boolean;
}>`
  position: relative;
  cursor: pointer;

  --size: 45px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 26px;
    color: ${(props) => props.theme.colors.text}99;

    ${(props) =>
      props.$isFaded
        ? css`
            opacity: 0.4;
          `
        : css``}

    ${(props) =>
      props.$isHighlighted
        ? css`
            color: ${(props) => props.theme.colors.main};
          `
        : css``}

    ${(props) =>
      props.$isWarning
        ? css`
            color: ${(props) => props.theme.colors.warning};
          `
        : css``}
  }
`;

const StorageIcon = styled.div<{ $isUploading?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.1s ease;

    ${(props) =>
      props.$isUploading
        ? css`
            font-size: 18px;
          `
        : css`
            font-size: 24px;
          `}
  }
`;

const Spinner = styled.div`
  position: absolute;
`;

const AppHeader = () => {
  const { isUploading, isOffline } = useGlobalStorage();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <Wrapper>
        <HeaderContent>
          <Icon
            $isHighlighted={isUploading}
            $isWarning={isOffline}
            $isFaded={!isOffline && !isUploading}
          >
            <StorageIcon $isUploading={isUploading}>
              {isOffline ? (
                <MdFlight style={{ transform: "scale(1.1)" }} />
              ) : (
                <FaGoogleDrive />
              )}
            </StorageIcon>

            {isUploading ? (
              <Spinner>
                <LoadingSpinner diameter="34px" size="1px" />
              </Spinner>
            ) : null}
          </Icon>

          <Link to={PageSlugs.homepage}>
            <Icon>
              <GoHomeFill />
            </Icon>
          </Link>

          <Icon onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <GrMenu />
          </Icon>
        </HeaderContent>

        <HeaderGradient />
      </Wrapper>

      <AppMenu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} />
    </>
  );
};

export default AppHeader;
