import {
  getMealsProgress,
  getSupplementsProgress,
  getWorkoutProgress,
} from "utils";

import { DayType } from "types";
import LinkBox from "components/LinkBox";
import { PageSlugs } from "pages";

const DayLink = (props: { workoutId: string; day: DayType }) => {
  const { workoutId, day } = props;

  const progressMeals = getMealsProgress(day);
  const progressSupplements = getSupplementsProgress(day);
  const progressWorkout = getWorkoutProgress(day);

  return (
    <LinkBox
      label={day.name}
      to={`${PageSlugs.day
        .replace(":workoutId", workoutId)
        .replace(":dayId", day.id)}`}
      progressData={{
        meals: progressMeals,
        supplements: progressSupplements,
        ...(day.exercises?.length
          ? { workout: progressWorkout }
          : { restDay: 1 }),
      }}
    />
  );
};

export default DayLink;
