import styled from "styled-components";

const Wrapper = styled.div<{ $diameter: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.$diameter}px;
  height: ${(props) => props.$diameter}px;
`;

const Svg = styled.svg`
  transform: rotate(-90deg); /* Start progress from the top */
  width: 100%;
  height: 100%;
`;

const CircleBackground = styled.circle<{
  $strokeWidth: number;
}>`
  fill: none;
  stroke: ${(props) => props.theme.colors.text}66;
  stroke-width: ${(props) => props.$strokeWidth}px;
`;

const CircleProgress = styled.circle<{
  $progress: number;
  $strokeWidth: number;
  $circumference: number;
}>`
  fill: none;
  stroke: ${(props) => props.theme.colors.main};
  stroke-width: ${(props) => props.$strokeWidth}px;
  stroke-dasharray: ${(props) => props.$circumference};
  stroke-dashoffset: ${(props) => (1 - props.$progress) * props.$circumference};
  transition: stroke-dashoffset 0.3s ease; /* Smooth animation */
`;

const Text = styled.div<{
  $progress: number;
}>`
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text}88;
`;

const ProgressCircle = (props: {
  progress: number; // Value between 0 and 1
  diameter?: number;
  strokeWidth?: number;
  textOverride?: string;
  textFontSize?: string;
  textFontWeight?: string;
  children?: any;
}) => {
  const {
    progress,
    diameter = 200,
    strokeWidth = 10,
    textOverride,
    textFontSize,
    textFontWeight,
  } = props;

  // Ensure progress is within 0-1 range
  const normalizedProgress = Math.min(Math.max(progress, 0), 1);

  const radius = (diameter - strokeWidth) / 2; // Adjust radius to fit within the diameter
  const circumference = 2 * Math.PI * radius;
  const percentage = Math.round(normalizedProgress * 100);

  return (
    <Wrapper $diameter={diameter}>
      <Svg viewBox={`0 0 ${diameter} ${diameter}`}>
        {/* Background circle */}
        <CircleBackground
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          $strokeWidth={strokeWidth}
        />
        {/* Progress circle */}
        <CircleProgress
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          $progress={normalizedProgress}
          $strokeWidth={strokeWidth}
          $circumference={circumference}
        />
      </Svg>

      {/* Percentage text */}
      <Text
        $progress={normalizedProgress}
        style={{ fontSize: textFontSize, fontWeight: textFontWeight }}
      >
        {props.children
          ? props.children
          : textOverride
          ? textOverride
          : `${percentage}%`}
      </Text>
    </Wrapper>
  );
};

export default ProgressCircle;
