import { ExerciseType } from "types";

export const getExerciseCompletedSetCount = (exercise: ExerciseType) => {
  return exercise?.sets?.filter((set) => set.isCompleted)?.length;
};

export const getExerciseIsCompleted = (exercise: ExerciseType) => {
  const completedCount = getExerciseCompletedSetCount(exercise);
  return exercise.sets.length === completedCount;
};

export const getExerciseProgress = (exercise: ExerciseType) => {
  const completedCount = getExerciseCompletedSetCount(exercise);
  return exercise.sets.length ? completedCount / exercise.sets.length : 0;
};
