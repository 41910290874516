export const LOCAL_STORAGE_IS_OFFLINE = "fitnessTracker_IsOffline";
export const LOCAL_STORAGE_DATA = "fitnessTracker_Data";
export const LOCAL_STORAGE_DATA_OVERRIDE = "fitnessTracker_DataOverride";

export enum DataOverride {
  "from_localStorage_to_googleDrive" = "from_localStorage_to_googleDrive",
  "from_googleDrive_to_localStorage" = "from_googleDrive_to_localStorage",
}

export const saveToLocalStorage = (key: string, value: unknown): void => {
  const serializedValue = JSON.stringify(value);
  localStorage.setItem(key, serializedValue);
};

export const loadFromLocalStorage = <T>(key: string): T | null => {
  const serializedValue = localStorage.getItem(key);
  if (serializedValue === null) {
    return null;
  }
  return JSON.parse(serializedValue) as T;
};

export const deleteFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};
