/* eslint-disable react-hooks/exhaustive-deps */

import {
  DataOverride,
  LOCAL_STORAGE_DATA,
  LOCAL_STORAGE_DATA_OVERRIDE,
  deleteFromLocalStorage,
  downloadJsonFile,
  generateUniqueId,
  getValidToken,
  googleSignIn,
  googleSignOut,
  loadFromLocalStorage,
  saveToLocalStorage,
  updateJsonFile,
} from "utils";
import {
  DataType,
  DayType,
  PopUpType,
  PopUpTypeEnum,
  TimerType,
  WeightType,
} from "types";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import OfflineModePopUp from "components/OfflineModePopUp";

interface GlobalStorageType {
  data: DataType;
  timer: TimerType;
  popUps: PopUpType[];
  isOffline: boolean;
  isSignedIn: boolean;
  isLoading: boolean;
  isUploading: boolean;
}

interface GlobalStorageContextType {
  state: GlobalStorageType;
  setState: Dispatch<SetStateAction<GlobalStorageType>>;
}

export enum StorageType {
  "googleDrive" = "googleDrive",
  "localStorage" = "localStorage",
}

//
// CONTEXT
//
const GlobalStorageContext = createContext<
  GlobalStorageContextType | undefined
>(undefined);

//
// PROVIDER
//
const DEFAULT_STATE: GlobalStorageType = {
  data: undefined,
  timer: undefined,
  popUps: [],
  isOffline: undefined,
  isSignedIn: false,
  isLoading: false,
  isUploading: false,
};

export const GlobalStorageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<GlobalStorageType>(DEFAULT_STATE);

  return (
    <GlobalStorageContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStorageContext.Provider>
  );
};

//
// HOOK
//
export const useGlobalStorage = () => {
  const { state, setState } = useContext(GlobalStorageContext);

  const isOfflineRef = useRef(state.isOffline);

  useEffect(() => {
    isOfflineRef.current = state.isOffline;
  }, [state.isOffline]);

  useEffect(() => {
    if (state?.isLoading) {
      fns.closePopUps();
    }
  }, [state?.isLoading]);

  // CAREFUL - ONLY FILES CREATED VIA API WORK, DON'T KNOW WHY
  const fileId = "1RryzJDEwV0sck8cUk1XiIJgrfb9j_p3p"; // TODO - MOVE TO ENV + CREATE PROD + DEV FILE IN GOOGLE DRIVE

  //
  // PRIVATE FUNCS
  //
  const loadDataWrapper: (
    getData: () => Promise<DataType>
  ) => Promise<DataType> = async (getData) => {
    setState((prevState) => {
      return { ...prevState, data: undefined, isLoading: true };
    });

    let loadedData = null;

    try {
      loadedData = await getData();

      setState((prevState) => {
        return { ...prevState, data: loadedData, isLoading: false };
      });
    } catch (error) {
      fns.handleError({
        title: "Google Drive download failed",
        error,
      });
    } finally {
      setState((prevState) => {
        return { ...prevState, isLoading: false };
      });

      return loadedData;
    }
  };

  const saveDataWrapper: (
    saveData: () => Promise<void>
  ) => Promise<void> = async (saveData) => {
    setState((prevState) => {
      return { ...prevState, isUploading: true };
    });

    try {
      await saveData();

      setState((prevState) => {
        return { ...prevState, isUploading: false };
      });
    } catch (error) {
      fns.handleError({
        title: "Google Drive upload failed",
        error,
      });
    } finally {
      setState((prevState) => {
        return { ...prevState, isUploading: false };
      });
    }
  };

  // Google Drive
  const loadDataFromGoogleDrive = () => {
    const getData: () => Promise<DataType> = async () => {
      const accessToken = await getValidToken();
      const downloadedJson = await downloadJsonFile(accessToken, fileId);
      console.info("Data downloaded from Google Drive", downloadedJson);
      return downloadedJson;
    };
    return loadDataWrapper(getData);
  };

  const saveDataToGoogleDrive = (data: DataType) => {
    const saveData: () => Promise<void> = async () => {
      const accessToken = await getValidToken();
      await updateJsonFile(accessToken, fileId, data);
      console.info("Data uploaded to Google Drive");
    };
    return saveDataWrapper(saveData);
  };

  // Local Storage
  const loadDataFromLocalStorage = async () => {
    const getData: () => Promise<DataType> = async () => {
      const downloadedJson = await loadFromLocalStorage<DataType>(
        LOCAL_STORAGE_DATA
      );
      console.info("Data loaded from local storage", downloadedJson);
      return downloadedJson;
    };
    return loadDataWrapper(getData);
  };

  const saveDataToLocalStorage = async (data: DataType) => {
    const saveData: () => Promise<void> = async () => {
      await saveToLocalStorage(LOCAL_STORAGE_DATA, data);
      console.info("Data stored in local storage");
    };
    return saveDataWrapper(saveData);
  };

  //
  // PUBLIC FUNCS
  //
  const fns = {
    //
    // Google Sign In / Out
    //
    signIn: async (overrideIsOffline?: boolean) => {
      try {
        const isOffline =
          overrideIsOffline === undefined
            ? isOfflineRef.current
            : overrideIsOffline;
        let isSignedIn = false;

        if (!isOffline) {
          const token = await googleSignIn();
          isSignedIn = !!token;
        } else {
          isSignedIn = true;
        }

        if (isSignedIn) {
          setState((prevState) => {
            return { ...prevState, isSignedIn };
          });

          fns.initData();
        } else {
          throw new Error("Token not returned.");
        }
      } catch (error) {
        fns.handleError({
          title: "Google Sign In Failed",
          error,
        });
      }
    },

    signOut: async () => {
      if (!isOfflineRef.current) {
        googleSignOut();
      }

      setState((prevState) => {
        return { ...prevState, isSignedIn: false };
      });
    },

    //
    // Init data
    //
    initData: async () => {
      const dataOverride = loadFromLocalStorage(LOCAL_STORAGE_DATA_OVERRIDE);

      if (dataOverride) {
        const isOffline = isOfflineRef.current;

        // COPY DATA FROM LOCAL STORAGE TO (CURRENTLY ACTIVE) GOOGLE DRIVE
        if (
          !isOffline &&
          dataOverride === DataOverride.from_localStorage_to_googleDrive
        ) {
          const data = await fns.loadData({
            storageType: StorageType.localStorage,
          });

          console.log("data", data);

          await fns.saveData({
            editedData: data,
            storageType: StorageType.googleDrive,
          });
        }

        // COPY DATA FROM GOOGLE DRIVE TO (CURRENTLY ACTIVE) LOCAL STORAGE
        else if (
          isOffline &&
          dataOverride === DataOverride.from_googleDrive_to_localStorage
        ) {
          /**
           * this is handled in the isOffline switch
           * - at the time of switching from online to offline, google drive data is already acquired
           * - fetching google drive data now would require google login, which is disabled in offline mode
           * -> handle copying data from local storage to google drive in isOffline change immediately (while the token is active)
           */
        }

        deleteFromLocalStorage(LOCAL_STORAGE_DATA_OVERRIDE);
      } else {
        await fns.loadData();
      }
    },

    //
    // Save (based on online / offline mode)
    //
    loadData: async (params?: { storageType?: StorageType }) => {
      const type: StorageType =
        params?.storageType ??
        (isOfflineRef.current
          ? StorageType.localStorage
          : StorageType.googleDrive);

      switch (type) {
        case StorageType.localStorage:
          return loadDataFromLocalStorage();
        case StorageType.googleDrive:
          return loadDataFromGoogleDrive();
      }
    },

    saveData: async (params?: {
      editedData?: DataType;
      storageType?: StorageType;
    }) => {
      const data = params?.editedData ?? state.data;

      const type: StorageType =
        params?.storageType ??
        (isOfflineRef.current
          ? StorageType.localStorage
          : StorageType.googleDrive);

      switch (type) {
        case StorageType.localStorage:
          return saveDataToLocalStorage(data);
        case StorageType.googleDrive:
          return saveDataToGoogleDrive(data);
      }
    },

    //
    // Offline Mode
    //
    setIsOfflineRaw: (isOffline: boolean) => {
      setState((prevState) => {
        return {
          ...prevState,
          isOffline: isOffline ?? false,
        };
      });
    },

    setOfflineMode: (isOffline: boolean) => {
      const popUpId = "offline-mode-popup";

      fns.showPopUp({
        id: popUpId,
        title: isOffline ? "Zapnout offline mode" : "Vypnout offline mode",
        content: <OfflineModePopUp isOffline={isOffline} popUpId={popUpId} />,
      });
    },

    //
    // Global Timer
    //
    setTimer: (timer: TimerType) => {
      setState((prevState) => {
        return { ...prevState, timer };
      });
    },

    //
    // Errors
    //
    handleError: (params: { title: string; error: Error }) => {
      const { title, error } = params;

      console.error(`${title}:`, error);
      fns.showPopUpError({
        title,
        message: error.message,
      });
    },

    //
    // Pop-ups
    //
    showPopUp: (popUp: Partial<PopUpType>) => {
      const newPopUp: PopUpType = { id: generateUniqueId(), ...popUp };

      setState((prevState) => {
        return {
          ...prevState,
          popUps: !prevState.popUps
            ? [newPopUp]
            : [newPopUp, ...prevState.popUps],
        };
      });
    },

    showPopUpError: (params: { title: string; message: string }) => {
      const newPopUp: Partial<PopUpType> = {
        type: PopUpTypeEnum.error,
        title: "Error",
        content: (
          <div>
            <div>{params.title}</div>
            <br />
            <div>{params.message}</div>
          </div>
        ),
      };
      fns.showPopUp(newPopUp);
    },

    closePopUp: (id: string) => {
      setState((prevState) => {
        return {
          ...prevState,
          popUps: prevState.popUps?.filter((p) => p.id !== id),
        };
      });
    },

    closePopUps: () => {
      setState((prevState) => {
        return {
          ...prevState,
          popUps: undefined,
        };
      });
    },

    //
    // Update Day
    //
    updateWeight: (params: { workoutId: string; newWeight: WeightType }) => {
      const { workoutId, newWeight } = params;

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            workoutPlans: prevState.data.workoutPlans.map((workout) =>
              workout.id === workoutId
                ? {
                    ...workout,
                    weight: newWeight,
                  }
                : workout
            ),
          },
        };

        return newState;
      });
    },

    updateDay: (params: {
      workoutId: string;
      dayId: string;
      newDay: DayType;
    }) => {
      const { workoutId, dayId, newDay } = params;

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            workoutPlans: prevState.data.workoutPlans.map((workout) =>
              workout.id === workoutId
                ? {
                    ...workout,
                    days: workout.days.map((day) =>
                      day.id === dayId ? newDay : day
                    ),
                  }
                : workout
            ),
          },
        };

        return newState;
      });
    },
  };

  return {
    data: state.data,
    timer: state.timer,
    popUps: state.popUps,
    isSignedIn: state.isSignedIn,
    isOffline: state.isOffline,
    isOfflineRef,
    isLoading: state.isLoading,
    isUploading: state.isUploading,
    fns,
  };
};
