import PublicPages from "pages-public";

const AppPublic = () => {
  //
  // RENDER
  //
  return (
    <>
      <PublicPages />
    </>
  );
};

export default AppPublic;
