/* eslint-disable react-hooks/exhaustive-deps */

import { DayType, MealType } from "types";
import { useEffect, useState } from "react";

import Button from "components/Button";
import CheckContainer from "components/CheckContainer";
import MealOption from "./MealOption";
import MealSuggestions from "./MealSuggestions";
import { RiRestaurant2Line } from "react-icons/ri";
import { getMealsProgress } from "utils";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px;
  padding-right: 0px;
  padding-bottom: 60px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px 0;
  padding-left: 20px;
`;

const InfoText = styled.div`
  color: ${(props) => props.theme.colors.text}88;
  font-size: 16px;
`;

const MealOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`;

const InfoSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const Meal = (props: { workoutId: string; day: DayType; meal: MealType }) => {
  const { workoutId, day, meal } = props;

  const { data, fns } = useGlobalStorage();

  const [isOpen, setIsOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const isTotalCompleted = getMealsProgress(day) === 1;

  const workout = data?.workoutPlans?.find((p) => p.id === workoutId);

  const mealSuggestions = workout?.mealSuggestions?.find((ms) =>
    ms.mealIds?.includes(meal.id)
  )?.suggestions;

  useEffect(() => {
    setIsCompleted(
      meal.customOption?.isCompleted ||
        !!meal.options?.find((mealOption) => mealOption.isCompleted)
    );
  }, [meal]);

  useEffect(() => {
    if (isCompleted) {
      setIsOpen(false);
    }
  }, [isCompleted]);

  //
  // SUGGESTIONS POPUP
  //
  const popUpId = `${workoutId}/${day.id}/${meal.id}/suggestions`;

  const onMealSuggestions = () => {
    fns.showPopUp({
      id: popUpId,
      title: "Vybrat jídlo",
      noPadding: true,
      content: (
        <MealSuggestions
          suggestions={mealSuggestions}
          onSuggestionSelected={onSuggestionSelected}
        />
      ),
    });
  };

  const onSuggestionSelected = (s: string) => {
    fns.closePopUp(popUpId);

    const newMeals: MealType[] = day.meals.map((m) =>
      m.id === meal.id
        ? {
            ...m,
            customOption: { ...(m.customOption ?? {}), text: s },
          }
        : m
    );

    const newDay: DayType = { ...day, meals: newMeals };
    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  //
  // RENDER
  //
  return (
    <CheckContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      name={meal.name}
      icon={RiRestaurant2Line}
      isTotalCompleted={isTotalCompleted}
      isCompleted={isCompleted}
    >
      <Content>
        {meal.text ? (
          <Info>
            <InfoText>{meal.text}</InfoText>
          </Info>
        ) : null}

        <MealOptions>
          {meal.options.map((mealOption, i) => (
            <MealOption
              key={i}
              workoutId={workoutId}
              day={day}
              meal={meal}
              mealIsCompleted={isCompleted}
              mealOptionIndex={i}
              mealOption={mealOption}
              containerIsOpen={isOpen}
            />
          ))}

          <MealOption
            workoutId={workoutId}
            day={day}
            meal={meal}
            mealIsCompleted={isCompleted}
            containerIsOpen={isOpen}
            isCustom
          />
        </MealOptions>

        <InfoSection>
          <Button
            label="vybrat z nabídky"
            onClick={onMealSuggestions}
            small
            faded
          />
        </InfoSection>
      </Content>
    </CheckContainer>
  );
};

export default Meal;
