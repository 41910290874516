import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  padding-bottom: 30px;
`;

const Title = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text};
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}88;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}88;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
`;

const PageHeader = (props: {
  title?: string;
  subtitleTop?: string;
  subtitleBottom?: string;
  text?: string;
}) => {
  return (
    <Wrapper>
      {props.subtitleTop ? <Subtitle>{props.subtitleTop}</Subtitle> : null}

      {props.title ? <Title>{props.title}</Title> : null}

      {props.subtitleBottom ? (
        <Subtitle>{props.subtitleBottom}</Subtitle>
      ) : null}

      {props.text ? <Text>{props.text}</Text> : null}
    </Wrapper>
  );
};

export default PageHeader;
