import styled, { css } from "styled-components";

import PopUp from "./PopUp";
import { useGlobalStorage } from "context/GlobalStorage";

const Wrapper = styled.div<{ $isVisible?: boolean }>`
  ${(props) =>
    props.$isVisible
      ? css``
      : css`
          pointer-events: none;
        `}
`;

const Overlay = styled.div<{ $isVisible?: boolean }>`
  pointer-events: none;
  position: fixed;
  z-index: ${(props) => props.theme.layout.zIndexPopUpsOverlay};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.backgroundDark}DD;
  transition: all 0.2s ease;

  ${(props) =>
    props.$isVisible
      ? css``
      : css`
          opacity: 0;
        `}
`;

const Content = styled.div``;

const PopUps = () => {
  const { popUps } = useGlobalStorage();

  return (
    <Wrapper $isVisible={!!popUps?.length}>
      <Overlay $isVisible={!!popUps?.length} />
      <Content>
        {popUps?.map((popUp, i) => (
          <PopUp key={popUp.id} popUp={popUp} index={i} isActive={i === 0} />
        ))}
      </Content>
    </Wrapper>
  );
};

export default PopUps;
