import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  gap: 8px;
`;

const Label = styled.div`
  flex: 1;
  margin-left: 2px;

  color: ${(props) => props.theme.colors.text}88;
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
`;

const InputWrapper = (props: { label?: string; children?: any }) => {
  const { label } = props;

  return (
    <Wrapper>
      {label ? <Label>{`${label}:`}</Label> : null}

      {props.children}
    </Wrapper>
  );
};

export default InputWrapper;
