import Exercise, { EXERCISE_OPEN_DURATION_SEC } from "components/Exercise";
import { getWorkoutMinTimeToComplete, getWorkoutProgress } from "utils";

import InfoBox from "components/InfoBox";
import LayoutMain from "layouts/LayoutMain";
import { MdOutlineTimer } from "react-icons/md";
import NoDataScreen from "components/NoDataScreen";
import PageHeader from "components/PageHeader";
import ProgressCircle from "components/ProgressCircle";
import styled from "styled-components";
import { useDay } from "hooks/useDay";
import { useParams } from "react-router-dom";
import { useState } from "react";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  padding-bottom: 60vh;
`;

const Header = styled.div`
  position: fixed;
  z-index: 101;
  top: 9px;
  left: 14px;
`;

const Exercises = styled.div`
  margin-bottom: 30px;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
`;

const DayWorkoutPage = () => {
  const { workoutId, dayId } = useParams<{
    workoutId: string;
    dayId: string;
  }>();
  const day = useDay({ workoutId, dayId });

  const [forceOpenExerciseIndex, setForceOpenExerciseIndex] =
    useState<number>();

  if (!day) {
    return <NoDataScreen />;
  }

  const progress = getWorkoutProgress(day);
  const timeToCompleteMinutes = getWorkoutMinTimeToComplete(day);

  const formattedTimeToCompleteMinutes = `${Math.floor(
    timeToCompleteMinutes / 60
  )
    .toString()
    .padStart(2, "0")}:${Math.floor(timeToCompleteMinutes % 60)
    .toString()
    .padStart(2, "0")}`;

  return (
    <LayoutMain>
      <PageHeader title={`Trénink`} subtitleTop={day.name} />

      <Wrapper>
        <Header>
          <ProgressCircle
            progress={progress}
            diameter={30}
            strokeWidth={2}
            textFontSize="9px"
            textFontWeight="400"
          />
        </Header>

        <Exercises>
          {day.exercises?.map((exercise, i) => (
            <Exercise
              key={i}
              day={day}
              workoutId={workoutId}
              workoutIsComplete={progress === 1}
              exerciseIndex={i}
              exercise={exercise}
              forceOpen={
                forceOpenExerciseIndex !== undefined &&
                forceOpenExerciseIndex === i
              }
              onOpenChange={() => setForceOpenExerciseIndex(undefined)}
              onCompleted={() =>
                setTimeout(() => {
                  setForceOpenExerciseIndex(i + 1);
                }, EXERCISE_OPEN_DURATION_SEC * 1000)
              }
            />
          ))}
        </Exercises>

        <InfoBox text={day.exercisesText} />

        <InfoBox icon={MdOutlineTimer}>
          <div>Odhadovaný čas:</div>
          <div>
            <strong>{formattedTimeToCompleteMinutes}</strong>

            {/* <div>Rozcvička:</div>
    <div>{`${WARMUP_AVG_TIME_MINUTES} min`}</div>
    <div>Jedno opakování:</div>
    <div>{`${SET_REP_AVG_TIME_SEC} sec`}</div>
    <div>Přesun na další cvik:</div>
    <div>{`${EXERCISE_AVG_CHANGE_MINUTES} min`}</div>
    <div>Konečné protažení:</div>
    <div>{`${END_AVG_TIME_MINUTES} min`}</div> */}
          </div>
        </InfoBox>
      </Wrapper>
    </LayoutMain>
  );
};

export default DayWorkoutPage;
