/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";

import { useGlobalStorage } from "context/GlobalStorage";
import { useLocation } from "react-router-dom";

export const useAutoSaveReload = () => {
  const location = useLocation();

  const { data, isSignedIn, isLoading, isUploading, timer, fns, isOfflineRef } =
    useGlobalStorage();

  const [pendingSave, setPendingSave] = useState(false);

  const isFirstLoadRef = useRef(true);
  const lastDataChangeTimeRef = useRef<number | undefined>(undefined);
  const lastChangeThresholdMinutes = 5;

  //
  // AUTO-SAVE ON DATA CHANGE
  //
  useEffect(() => {
    const saveData = async () => {
      if (data && !isLoading && !isUploading && isSignedIn) {
        // ON DATA INIT
        if (isFirstLoadRef.current) {
          console.info("Data initialized");
        }

        // SAVE DATA IF NOT INITIAL DATA LOAD
        else {
          console.info("Saving data...");
          await fns.saveData();
        }

        lastDataChangeTimeRef.current = new Date().getTime();
        isFirstLoadRef.current = false;
      } else if (data && isUploading && !pendingSave) {
        console.info("Marking save as pending...");
        setPendingSave(true);
      }
    };

    saveData();
  }, [data]);

  //
  // HANDLE PENDING SAVE AFTER UPLOADING IS FINISHED
  //
  useEffect(() => {
    const handlePendingSave = async () => {
      if (pendingSave && !isUploading) {
        console.info("Executing pending save...");

        await fns.saveData();

        // Reset the pending save flag only after the save is complete
        setPendingSave(false);
      }
    };

    handlePendingSave();
  }, [pendingSave, isUploading]);

  //
  // HANDLE AUTO RELOAD WHEN TIMER IS IN USE
  //
  const timerIsActiveRef = useRef(!!timer?.durationMinutes);

  useEffect(() => {
    const timerIsActive = !!timer?.durationMinutes;
    timerIsActiveRef.current = timerIsActive;
    if (!timerIsActive) {
      // when timer becomes inactive, reset refresh time
      lastDataChangeTimeRef.current = new Date().getTime();
    }
  }, [timer]);

  //
  // MONITOR CLICK EVENTS TO REQUEST DATA REFRESH
  //
  const getReloadIsNeeded = () => {
    if (isOfflineRef.current) {
      // local storage doesn't need reload, data is not accessible from anywhere else
      return false;
    }

    const now = new Date().getTime();

    return (
      !timerIsActiveRef.current &&
      lastDataChangeTimeRef.current !== undefined &&
      now - lastDataChangeTimeRef.current >
        lastChangeThresholdMinutes * 60 * 1000
    );
  };

  const reloadData = async () => {
    console.info("Reloading data");
    lastDataChangeTimeRef.current = new Date().getTime();
    isFirstLoadRef.current = true;
    await fns.loadData();
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (getReloadIsNeeded()) {
        e.preventDefault();
        e.stopImmediatePropagation();
        reloadData();
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  useEffect(() => {
    if (getReloadIsNeeded()) {
      reloadData();
    }
  }, [location]);

  return null;
};
