import LayoutCentered from "layouts/LayoutCentered";
import styled from "styled-components";

const Text = styled.div`
  font-size: 60px;
`;

const NoDataScreen = () => {
  // TODO - PRETTY ERROR

  return (
    <LayoutCentered>
      <Text>404</Text>
    </LayoutCentered>
  );
};

export default NoDataScreen;
