import { DayType, ExerciseType } from "types";

export const WARMUP_AVG_TIME_MINUTES = 15;
export const SET_REP_AVG_TIME_SEC = 2.5; // 4 seconds per rep
export const EXERCISE_AVG_CHANGE_MINUTES = 2; // average time to move on to the next exercise
export const END_AVG_TIME_MINUTES = 6;

export const getExerciseMinTimeToComplete = (
  day: DayType,
  exercise: ExerciseType
) => {
  let exerciseMinutes = 0;
  let pauseMinutes = 0;

  // Exercise duration
  exercise.sets?.forEach((set) => {
    if (exercise.isCardio) {
      exerciseMinutes += set.durationMinutes || 0;
    } else {
      exerciseMinutes += set.repCount * (SET_REP_AVG_TIME_SEC / 60);
    }
  });

  // Pauses between sets
  const setCount = exercise.sets?.length;

  if (setCount > 1) {
    const currPause =
      exercise.pauseBetweenSetsMinutes !== undefined
        ? exercise.pauseBetweenSetsMinutes
        : day.defaultPauseBetweenSetsMinutes;

    pauseMinutes += currPause * setCount - 1;
  }

  const total = exerciseMinutes + pauseMinutes;

  return total;
};

export const getWorkoutMinTimeToComplete = (day: DayType) => {
  let exerciseMinutes = 0;
  let changeExerciseMinutes = 0;

  day.exercises?.forEach((exercise) => {
    exerciseMinutes += getExerciseMinTimeToComplete(day, exercise);
    changeExerciseMinutes += EXERCISE_AVG_CHANGE_MINUTES;
  });

  return (
    WARMUP_AVG_TIME_MINUTES +
    exerciseMinutes +
    changeExerciseMinutes +
    END_AVG_TIME_MINUTES
  );
};
