import LayoutPublic from "layouts/LayoutPublic";
import WorkoutPlanOverview from "components/WorkoutPlanOverview";
import { useGlobalStorage } from "context/GlobalStorage";
import { useParams } from "react-router-dom";

const DataPreview = () => {
  const { data } = useGlobalStorage();
  const { workoutId } = useParams<{
    workoutId: string;
  }>();

  const workout = data?.workoutPlans?.find((p) => p.id === workoutId);

  return (
    <LayoutPublic>
      {workout ? <WorkoutPlanOverview dataWorkoutPlan={workout} /> : null}
    </LayoutPublic>
  );
};

export default DataPreview;
