import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { IconType } from "react-icons";
import { useState } from "react";

const Wrapper = styled.div<{ $level: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transition: all 0.2s ease;

  ${(props) =>
    props.$level === 0
      ? css`
          gap: 10px;
        `
      : css`
          gap: 10px;
        `}
`;

const Header = styled.div<{ $level: number }>`
  cursor: pointer;

  flex: 1;
  display: flex;
  align-items: center;

  gap: 10px;
  border-radius: 4px;
  padding: 4px 10px;
  padding-right: 6px;

  ${(props) =>
    props.$level === 0
      ? css`
          background: ${(props) => props.theme.colors.text}15;
        `
      : props.$level === 1
      ? css`
          background: ${(props) => props.theme.colors.text}05;
        `
      : css`
          border-bottom: 1px solid ${(props) => props.theme.colors.text}22;
          background: ${(props) => props.theme.colors.text}02;
        `}
`;

const HeaderContent = styled.div<{ $level: number }>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;

  ${(props) =>
    props.$level === 0
      ? css`
          padding: 8px;

          color: ${(props) => props.theme.colors.main}EE;
          font-weight: 400;
          font-size: 18px;
          text-transform: uppercase;
        `
      : props.$level === 1
      ? css`
          padding: 8px;

          color: ${(props) => props.theme.colors.text}BB;
          font-weight: 400;
          font-size: 16px;
        `
      : css`
          padding: 5px 4px;

          color: ${(props) => props.theme.colors.text}BB;
          font-size: 14px;
          font-style: italic;
        `}
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;

  color: ${(props) => props.theme.colors.main};

  svg {
    font-size: 20px;
  }
`;

const Name = styled.div``;

const Children = styled.div<{ $level: number }>`
  padding-left: 10px;

  ${(props) =>
    props.$level === 0
      ? css`
          padding-bottom: 20px;
        `
      : props.$level === 1
      ? css`
          padding-bottom: 20px;
        `
      : css`
          padding-bottom: 20px;
        `}
`;

const Section = (props: {
  level?: number;
  label: string;
  icon?: IconType;
  isOpen?: boolean;
  children?: any;
}) => {
  const { level = 0, label, icon: SvgIcon } = props;

  const [isOpen, setIsOpen] = useState(props.isOpen);

  return (
    <Wrapper $level={level}>
      <Header $level={level} onClick={() => setIsOpen(!isOpen)}>
        <HeaderContent $level={level}>
          {SvgIcon ? (
            <Icon>
              <SvgIcon />
            </Icon>
          ) : null}
          <Name>{label}</Name>
        </HeaderContent>
      </Header>

      <AnimateHeight duration={300} height={isOpen ? "auto" : 0} easing="ease">
        <Children $level={level}>{props.children}</Children>
      </AnimateHeight>
    </Wrapper>
  );
};

export default Section;
