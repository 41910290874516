import AppPrivate from "AppPrivate";
import AppPublic from "AppPublic";
import PopUps from "components/PopUps";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");

  //
  // RENDER
  //
  return (
    <>
      {isPublic ? <AppPublic /> : <AppPrivate />}
      <PopUps />
    </>
  );
};

export default App;
