import {
  CustomSupplementOptionType,
  DayType,
  SupplementOptionType,
  SupplementType,
} from "types";

import CheckItem from "components/CheckItem";
import { useGlobalStorage } from "context/GlobalStorage";

const SupplementOption = (props: {
  workoutId: string;
  day: DayType;
  supplement: SupplementType;
  supplementIndex: number;
  supplementIsCompleted: boolean;
  supplementOptionIndex?: number;
  supplementOption?: SupplementOptionType;
  containerIsOpen?: boolean;
  isCustom?: boolean;
}) => {
  const {
    workoutId,
    day,
    supplementIndex,
    supplement,
    isCustom,
    supplementIsCompleted,
    supplementOptionIndex,
    supplementOption,
    containerIsOpen,
  } = props;

  const { fns } = useGlobalStorage();

  const isCompleted = isCustom
    ? supplement.customOption?.isCompleted
    : supplementOption.isCompleted;

  const isFaded = supplementIsCompleted && !isCompleted;

  //
  // UTILS
  //
  const setSupplementOption = (newSupplementOption: SupplementOptionType) => {
    const newSupplements: SupplementType[] = day.supplements.map((m, i) =>
      i === supplementIndex
        ? {
            ...m,
            options: m.options.map((set, j) =>
              j === supplementOptionIndex ? newSupplementOption : set
            ),
          }
        : m
    );

    const newDay: DayType = { ...day, supplements: newSupplements };
    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  const toggleSupplementOption = () => {
    setSupplementOption({
      ...supplementOption,
      isCompleted: !supplementOption.isCompleted,
    });
  };

  const setCustomSupplementOption = (
    newCustomSupplementOption: CustomSupplementOptionType
  ) => {
    const newSupplements: SupplementType[] = day.supplements.map((m, i) =>
      i === supplementIndex
        ? {
            ...m,
            customOption: newCustomSupplementOption,
          }
        : m
    );

    const newDay: DayType = { ...day, supplements: newSupplements };
    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  const toggleCustomSupplementOption = () => {
    setCustomSupplementOption({
      ...supplement.customOption,
      isCompleted: !supplement.customOption?.isCompleted,
    });
  };

  const setCustomSupplementOptionText = (newText) => {
    setCustomSupplementOption({ ...supplement.customOption, text: newText });
  };

  //
  // RENDER
  //
  if (isCustom) {
    return (
      <CheckItem
        isFaded={isFaded}
        isCompleted={supplement.customOption?.isCompleted}
        onCheckClick={toggleCustomSupplementOption}
        customText={{
          placeholder: "Vyplnit suplementy...",
          value: supplement.customOption?.text,
          onChange: setCustomSupplementOptionText,
        }}
        containerIsOpen={containerIsOpen}
      />
    );
  }

  return (
    <CheckItem
      items={supplementOption.ingredients}
      isFaded={isFaded}
      isCompleted={supplementOption?.isCompleted}
      onCheckClick={toggleSupplementOption}
      containerIsOpen={containerIsOpen}
    />
  );
};

export default SupplementOption;
