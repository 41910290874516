import { DayType } from "types";
import { getExerciseProgress } from "./exercise";

export const getWorkoutProgress = (day: DayType) => {
  if (!day.exercises?.length) {
    // rest days
    return 1;
  }

  let progress = 0;
  let total = 0;

  day.exercises?.forEach((exercise) => {
    progress += getExerciseProgress(exercise);
    total += 1;
  });

  return total ? Math.round((progress / total) * 100) / 100 : 0;
};
