/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { DataType } from "types";
import LayoutPublic from "layouts/LayoutPublic";
import LoadingScreen from "components/LoadingScreen";
import WorkoutPlanOverview from "components/WorkoutPlanOverview";
import { fetchPublicData } from "utils/google-drive/public";
import { useGlobalStorage } from "context/GlobalStorage";
import { useParams } from "react-router-dom";

const PublicDataPreview = () => {
  const { googleFileId } = useParams<{
    googleFileId: string;
  }>();

  const { fns } = useGlobalStorage();

  const [isLoading, setIsLoading] = useState(true);
  const [publicData, setPublicData] = useState<DataType>();

  useEffect(() => {
    if (googleFileId) {
      const asyncFunc = async () => {
        try {
          const data = await fetchPublicData({ fileId: googleFileId });

          if (data) {
            setPublicData(data);
          } else {
            throw new Error("Nepodařilo se načíst data.");
          }
        } catch (error) {
          fns.handleError({
            title: "Google Drive chyba",
            error,
          });
        }

        setIsLoading(false);
      };

      asyncFunc();
    }
  }, [googleFileId]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <LayoutPublic>
      {publicData?.workoutPlans[0] ? (
        <WorkoutPlanOverview dataWorkoutPlan={publicData?.workoutPlans[0]} />
      ) : null}
    </LayoutPublic>
  );
};

export default PublicDataPreview;
