import LayoutMain from "layouts/LayoutMain";
import WorkoutPlan from "components/WorkoutPlan";
import { useGlobalStorage } from "context/GlobalStorage";

const HomePage = () => {
  const { data } = useGlobalStorage();

  return (
    <LayoutMain>
      {data?.workoutPlans?.map((dataWorkoutPlan, i) => {
        return <WorkoutPlan key={i} dataWorkoutPlan={dataWorkoutPlan} />;
      })}
    </LayoutMain>
  );
};

export default HomePage;
