import { Route, Routes } from "react-router-dom";

import PublicDataPreview from "./PublicDataPreview";

export const PublicPageSlugs = {
  publicDataPreview: "public/data-preview/:googleFileId",
};

const PublicPages = () => {
  return (
    <Routes>
      <Route
        path={PublicPageSlugs.publicDataPreview}
        element={<PublicDataPreview />}
      />
    </Routes>
  );
};

export default PublicPages;
