import { CustomMealOptionType, DayType, MealOptionType, MealType } from "types";

import CheckItem from "components/CheckItem";
import { useGlobalStorage } from "context/GlobalStorage";

const MealOption = (props: {
  workoutId: string;
  day: DayType;
  meal: MealType;
  mealIsCompleted: boolean;
  mealOptionIndex?: number;
  mealOption?: MealOptionType;
  containerIsOpen?: boolean;
  isCustom?: boolean;
}) => {
  const {
    workoutId,
    day,
    meal,
    isCustom,
    mealIsCompleted,
    mealOptionIndex,
    mealOption,
    containerIsOpen,
  } = props;

  const { fns } = useGlobalStorage();

  const isCompleted = isCustom
    ? meal.customOption?.isCompleted
    : mealOption.isCompleted;

  const isFaded = mealIsCompleted && !isCompleted;

  //
  // UTILS
  //
  const setMealOption = (newMealOption: MealOptionType) => {
    const newMeals: MealType[] = day.meals.map((m) =>
      m.id === meal.id
        ? {
            ...m,
            options: m.options.map((set, j) =>
              j === mealOptionIndex ? newMealOption : set
            ),
          }
        : m
    );

    const newDay: DayType = { ...day, meals: newMeals };
    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  const toggleMealOption = () => {
    setMealOption({ ...mealOption, isCompleted: !mealOption.isCompleted });
  };

  const setCustomMealOption = (newCustomMealOption: CustomMealOptionType) => {
    const newMeals: MealType[] = day.meals.map((m) =>
      m.id === meal.id
        ? {
            ...m,
            customOption: newCustomMealOption,
          }
        : m
    );

    const newDay: DayType = { ...day, meals: newMeals };
    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  const toggleCustomMealOption = () => {
    setCustomMealOption({
      ...meal.customOption,
      isCompleted: !meal.customOption?.isCompleted,
    });
  };

  const setCustomMealOptionText = (newText) => {
    setCustomMealOption({ ...meal.customOption, text: newText });
  };

  //
  // RENDER
  //
  if (isCustom) {
    return (
      <CheckItem
        isFaded={isFaded}
        isCompleted={meal.customOption?.isCompleted}
        onCheckClick={toggleCustomMealOption}
        customText={{
          placeholder: "Vyplnit jídlo...",
          value: meal.customOption?.text,
          onChange: setCustomMealOptionText,
        }}
        containerIsOpen={containerIsOpen}
      />
    );
  }

  return (
    <CheckItem
      items={mealOption.ingredients}
      isFaded={isFaded}
      isCompleted={mealOption?.isCompleted}
      onCheckClick={toggleMealOption}
      containerIsOpen={containerIsOpen}
    />
  );
};

export default MealOption;
