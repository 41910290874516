import LayoutCentered from "layouts/LayoutCentered";
import LoadingSpinner from "components/LoadingSpinner";
import styled from "styled-components";

const Wrapper = styled.div``;

const LoadingScreen = () => {
  return (
    <LayoutCentered>
      <Wrapper>
        <LoadingSpinner diameter="200px" size="5px" />
      </Wrapper>
    </LayoutCentered>
  );
};

export default LoadingScreen;
