/* eslint-disable react-hooks/exhaustive-deps */

import styled, { css } from "styled-components";
import { useEffect, useState } from "react";

import { IoCloseSharp } from "react-icons/io5";
import { useGlobalStorage } from "context/GlobalStorage";

const Wrapper = styled.div<{ $isVisible?: boolean }>`
  --height: max(100px, 25vh);
  position: fixed;
  z-index: ${(props) => props.theme.layout.zIndexTimer};
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: ${(props) => props.theme.colors.backgroundDark};

  transition: all 0.2s ease;

  ${(props) =>
    props.$isVisible
      ? css``
      : css`
          transform: translateY(var(--height));
        `}
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  outline: none;
  background: none;

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.text}44;
  }
`;

const Content = styled.div<{ $isHighlighted: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  font-size: 100px;
  font-weight: bold;
  text-align: center;

  color: ${(props) =>
    props.$isHighlighted ? props.theme.colors.main : props.theme.colors.text};
`;

const Timer = () => {
  const { timer, fns } = useGlobalStorage();

  const timeToHighlightMinutes = 0.25;
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    setTimeLeft((timer?.durationMinutes || 0) * 60);
  }, [timer]);

  useEffect(() => {
    if (timeLeft <= 0) {
      fns.setTimer(undefined);
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Wrapper $isVisible={timeLeft > 0}>
      <Header>
        <CloseButton onClick={() => setTimeLeft(0)}>
          <IoCloseSharp />
        </CloseButton>
      </Header>

      <Content
        $isHighlighted={timeLeft > 0 && timeLeft < timeToHighlightMinutes * 60}
      >
        {formatTime(timeLeft)}
      </Content>
    </Wrapper>
  );
};

export default Timer;
