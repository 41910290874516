import { DayType, ExerciseSetType, ExerciseType } from "types";
import styled, { css } from "styled-components";

import { IoMdCheckmark } from "react-icons/io";
import { useGlobalStorage } from "context/GlobalStorage";

const MIN_ITEM_HEIGHT = "54px";
const BORDER_RADIUS = "4px";

const Wrapper = styled.div`
  --color-bg-todo: ${(props) => props.theme.colors.text}11;
  --color-bg-filled: ${(props) => props.theme.colors.text}08;
  --color-bg-done: ${(props) => props.theme.colors.main}11;

  flex: 1;
  display: flex;
  align-items: stretch;

  * {
    font-size: 16px;
  }
`;

const Text = styled.div<{ $isCompleted: boolean }>`
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding: 0 10px;
  color: ${(props) => props.theme.colors.text}AA;
  box-sizing: border-box;

  border-radius: ${BORDER_RADIUS};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  ${(props) =>
    props.$isCompleted
      ? css`
          background: var(--color-bg-done);
        `
      : css`
          background: var(--color-bg-filled);
        `}
`;

const Icon = styled.div<{ $isCompleted: boolean }>`
  cursor: pointer;
  min-width: ${MIN_ITEM_HEIGHT};
  min-height: ${MIN_ITEM_HEIGHT};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${BORDER_RADIUS};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  svg {
    font-size: 24px;
    margin-bottom: -2px;
  }

  ${(props) =>
    props.$isCompleted
      ? css`
          color: ${props.theme.colors.main};
          background: var(--color-bg-done);
        `
      : css`
          color: ${props.theme.colors.background};
          background: var(--color-bg-todo);
        `}
`;

const Input = styled.input<{ $isFilled: boolean; $isCompleted: boolean }>`
  flex: 1;
  min-width: 50px;
  min-height: ${MIN_ITEM_HEIGHT};
  outline: none;
  border: none;

  box-sizing: border-box;
  padding: 0px 10px;
  padding-left: 15px;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.text}AA;

  ${(props) =>
    props.$isCompleted
      ? css`
          background: var(--color-bg-done);
        `
      : props.$isFilled
      ? css`
          background: var(--color-bg-filled);
        `
      : css`
          background: var(--color-bg-todo);
        `}
`;

const ExerciseSet = (props: {
  workoutId: string;
  day: DayType;
  exerciseIndex: number;
  exercise: ExerciseType;
  setIndex: number;
  set: ExerciseSetType;
}) => {
  const { workoutId, day, exerciseIndex, exercise, setIndex, set } = props;

  const { fns } = useGlobalStorage();

  //
  // UTILS
  //
  const setExerciseSet = (newExerciseSet: ExerciseSetType) => {
    const newExercises: ExerciseType[] = day.exercises.map((ex, i) =>
      i === exerciseIndex
        ? {
            ...ex,
            sets: ex.sets.map((set, j) =>
              j === setIndex ? newExerciseSet : set
            ),
          }
        : ex
    );

    const newDay: DayType = { ...day, exercises: newExercises };

    fns.updateDay({ workoutId, dayId: day.id, newDay });
  };

  const toggleExerciseSet = () => {
    const isCompleted = !set.isCompleted;
    setExerciseSet({ ...set, isCompleted });
  };

  const setExerciseSetValue = (newValue: string) => {
    setExerciseSet({ ...set, value: newValue });
  };

  const getText = () => {
    if (exercise.isCardio && set.durationMinutes) {
      return `${set.durationMinutes} min`;
    }

    return `${set.repCount}x`;
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Text $isCompleted={set.isCompleted}>{getText()}</Text>

      <Input
        $isFilled={!!set.value?.length}
        $isCompleted={set.isCompleted}
        value={set.value || ""}
        placeholder={exercise.isCardio ? "? km" : "? kg"}
        onChange={(e) => setExerciseSetValue(e.target.value)}
      />

      <Icon $isCompleted={set.isCompleted} onClick={() => toggleExerciseSet()}>
        <IoMdCheckmark />
      </Icon>
    </Wrapper>
  );
};

export default ExerciseSet;
