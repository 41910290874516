import { DayType } from "types";
import { useGlobalStorage } from "context/GlobalStorage";
import { useMemo } from "react";

export const useDay = (props: {
  workoutId: string;
  dayId: string;
}): DayType | undefined => {
  const { workoutId, dayId } = props;
  const { data } = useGlobalStorage();

  const day = useMemo(() => {
    const workoutPlan = data?.workoutPlans?.find(
      (plan) => plan.id === workoutId
    );

    const currDay = workoutPlan?.days?.find((d) => d.id === dayId);
    return currDay;
  }, [data?.workoutPlans, workoutId, dayId]);

  return day;
};
