import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { IconType } from "react-icons";
import { IoMdCheckmark } from "react-icons/io";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transition: all 0.2s ease;
`;

const Header = styled.div<{
  $isTotalCompleted: boolean;
  $isCompleted: boolean;
}>`
  cursor: pointer;

  flex: 1;
  display: flex;
  align-items: center;

  gap: 10px;
  border-radius: 4px;
  padding: 4px 10px;
  padding-right: 6px;

  ${(props) =>
    props.$isCompleted && !props.$isTotalCompleted
      ? css`
          background: ${(props) => props.theme.colors.text}08;
        `
      : css`
          background: ${(props) => props.theme.colors.text}18;
        `}
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div<{ $isHighlighted?: boolean; $isFaded?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;

  transition: all 0.2s ease;

  svg {
    font-size: 24px;
  }

  ${(props) =>
    props.$isHighlighted
      ? css`
          color: ${(props) => props.theme.colors.main};
        `
      : css`
          color: ${(props) => props.theme.colors.text};
        `}

  ${(props) =>
    props.$isFaded
      ? css`
          opacity: 0.4;
        `
      : css``}
`;

const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
`;

const Index = styled.div`
  color: ${(props) => props.theme.colors.text}22;
`;

const Name = styled.div`
  color: ${(props) => props.theme.colors.text}BB;
`;

const CheckContainer = (props: {
  children?: any;
  isOpen?: boolean;
  setIsOpen?: (v: boolean) => void;
  openDurationSec?: number;

  index?: number;
  name: string;
  icon: IconType;
  isTotalCompleted?: boolean;
  isCompleted?: boolean;
  opacity?: number;
}) => {
  const {
    isOpen,
    setIsOpen,
    openDurationSec = 0.3,
    index,
    name,
    icon: CustomIcon,
    isTotalCompleted,
    isCompleted,
    opacity,
  } = props;

  //
  // RENDER
  //
  return (
    <Wrapper style={{ opacity }}>
      <Header
        $isTotalCompleted={isTotalCompleted}
        $isCompleted={isCompleted}
        onClick={() => setIsOpen(!isOpen)}
      >
        <HeaderContent>
          {index !== undefined ? <Index>{`${index + 1})`}</Index> : null}
          <Name>{name}</Name>
        </HeaderContent>

        <Icons>
          <Icon $isHighlighted={isCompleted}>
            {isCompleted ? <IoMdCheckmark /> : <CustomIcon />}
          </Icon>
        </Icons>
      </Header>

      <AnimateHeight
        duration={openDurationSec * 1000}
        height={isOpen ? "auto" : 0}
        easing="ease"
      >
        {props.children}
      </AnimateHeight>
    </Wrapper>
  );
};

export default CheckContainer;
