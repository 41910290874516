import styled, { css } from "styled-components";

import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import ProgressCircle from "components/ProgressCircle";

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  cursor: pointer;
  flex: 1;

  min-height: 90px;
  padding-left: 20px;
  padding-right: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  background: ${(props) => props.theme.colors.text}18;
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  text-decoration: none;

  border-radius: 5px;

  ${(props) =>
    props.disabled
      ? css`
          cursor: default;
          opacity: 0.7;
        `
      : css``}
`;

const Header = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;
`;

const Name = styled.div<{ $isCompleted?: boolean }>`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};

  ${(props) =>
    props.$isCompleted
      ? css`
          color: ${props.theme.colors.main};
        `
      : css``}
`;

const Progress = styled.div<{ $isCompleted?: boolean }>`
  font-size: 24px;
  color: ${(props) => props.theme.colors.todo};

  ${(props) =>
    props.$isCompleted
      ? css`
          color: ${props.theme.colors.main};
        `
      : css``}
`;

const ProgressWrapper = styled.div`
  position: relative;
`;

const CirclerWrapper = styled.div`
  opacity: 0.2;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.colors.text};
    font-size: 26px;
  }
`;

const DayTile = (props: {
  name: string;
  icon: IconType;
  progress?: number;
  linkTo?: string;
}) => {
  const { linkTo, name, progress, icon: Icon } = props;

  return (
    <StyledLink to={linkTo} disabled={!linkTo}>
      <Header>
        <Name $isCompleted={!linkTo}>{name}</Name>
        {progress !== undefined ? (
          <Progress $isCompleted={progress === 1}>{`${Math.round(
            progress * 100
          )}%`}</Progress>
        ) : null}
      </Header>

      <ProgressWrapper>
        <CirclerWrapper>
          <ProgressCircle
            progress={progress}
            diameter={60}
            strokeWidth={3}
            textOverride=" "
            textFontSize="9px"
            textFontWeight="400"
          />
        </CirclerWrapper>

        <IconWrapper>
          <Icon />
        </IconWrapper>
      </ProgressWrapper>
    </StyledLink>
  );
};

export default DayTile;
