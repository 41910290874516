import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi2";

import InputWrapper from "../InputWrapper";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  background: ${(props) => props.theme.colors.backgroundDark}44;
  border-radius: 4px;
`;

const Button = styled.div`
  cursor: pointer;

  --size: 45px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  svg {
    font-size: 26px;
    color: ${(props) => props.theme.colors.text}99;
  }
`;

const Input = styled.input`
  flex: 1;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none;
  padding: 10px 15px;

  color: ${(props) => props.theme.colors.main}AA;

  min-width: 10px;
  width: 10px;
  max-width: auto;

  font-family: inherit;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
`;

const NumberInput = (props: {
  label?: string;
  value: number;
  onChange?: (v: number) => void;
  step?: string;
  increment?: number;
  noManualInput?: boolean;
  onIncrementUp?: () => void;
  onIncrementDown?: () => void;
}) => {
  const {
    label,
    value,
    onChange,
    step = ".1",
    increment = 0.1,
    noManualInput = false,
    onIncrementUp,
    onIncrementDown,
  } = props;

  return (
    <InputWrapper label={label}>
      <Wrapper>
        <Button
          onClick={
            onIncrementDown
              ? onIncrementDown
              : () => onChange?.(Math.round((value - increment) * 100) / 100)
          }
        >
          <HiOutlineMinus />
        </Button>

        <Input
          type="number"
          step={step}
          min={0}
          value={value}
          onChange={(e) =>
            onChange?.(Math.round(parseFloat(e.target.value) * 100) / 100)
          }
          onBlur={(e) => (!e.target.value ? onChange?.(0) : undefined)}
          disabled={noManualInput}
        />

        <Button
          onClick={
            onIncrementUp
              ? onIncrementUp
              : () => onChange?.(Math.round((value + increment) * 100) / 100)
          }
        >
          <HiOutlinePlus />
        </Button>
      </Wrapper>
    </InputWrapper>
  );
};

export default NumberInput;
