import LayoutMain from "layouts/LayoutMain";
import Meal from "components/Meal";
import NoDataScreen from "components/NoDataScreen";
import PageHeader from "components/PageHeader";
import styled from "styled-components";
import { useDay } from "hooks/useDay";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Meals = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;

  padding-bottom: 20vh;
`;

const DayMealsPage = () => {
  const { workoutId, dayId } = useParams<{
    workoutId: string;
    dayId: string;
  }>();
  const day = useDay({ workoutId, dayId });

  if (!day) {
    return <NoDataScreen />;
  }

  return (
    <LayoutMain>
      <PageHeader title={`Jídlo`} subtitleTop={day.name} />

      <Wrapper>
        <Meals>
          {day.meals?.map((meal, i) => (
            <Meal key={i} day={day} workoutId={workoutId} meal={meal} />
          ))}
        </Meals>
      </Wrapper>
    </LayoutMain>
  );
};

export default DayMealsPage;
