import {
  getMealsProgress,
  getSupplementsProgress,
  getWorkoutProgress,
} from "utils";

import DayTile from "components/DayTile";
import { GiNightSleep } from "react-icons/gi";
import { IoFitness } from "react-icons/io5";
import LayoutMain from "layouts/LayoutMain";
import NoDataScreen from "components/NoDataScreen";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import { RiRestaurant2Line } from "react-icons/ri";
import { TbPill } from "react-icons/tb";
import styled from "styled-components";
import { useDay } from "hooks/useDay";
import { useParams } from "react-router-dom";

const Info = styled.p`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 16px;
  white-space: pre-wrap;
`;

const Tiles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;
`;

const DayPage = () => {
  const { workoutId, dayId } = useParams<{
    workoutId: string;
    dayId: string;
  }>();

  const day = useDay({ workoutId, dayId });

  if (!day) {
    return <NoDataScreen />;
  }

  const isRestDay = !day.exercises?.length;

  const progressMeals = getMealsProgress(day);
  const progressSupplements = getSupplementsProgress(day);
  const progressWorkout = getWorkoutProgress(day);

  return (
    <LayoutMain>
      <PageHeader title={day.name} />

      <Info>{day.text}</Info>

      <Tiles>
        <DayTile
          name="Jídlo"
          progress={progressMeals}
          icon={RiRestaurant2Line}
          linkTo={`${PageSlugs.dayMeals
            .replace(":workoutId", workoutId)
            .replace(":dayId", dayId)}`}
        />

        <DayTile
          name="Suplementy"
          progress={progressSupplements}
          icon={TbPill}
          linkTo={`${PageSlugs.daySupplements
            .replace(":workoutId", workoutId)
            .replace(":dayId", dayId)}`}
        />

        {isRestDay ? (
          <DayTile name="Rest Day" icon={GiNightSleep} />
        ) : (
          <DayTile
            name="Trénink"
            progress={progressWorkout}
            icon={IoFitness}
            linkTo={`${PageSlugs.dayWorkout
              .replace(":workoutId", workoutId)
              .replace(":dayId", dayId)}`}
          />
        )}
      </Tiles>
    </LayoutMain>
  );
};

export default DayPage;
