/* eslint-disable react-hooks/exhaustive-deps */

import { DayType, SupplementType } from "types";
import { useEffect, useState } from "react";

import CheckContainer from "components/CheckContainer";
import SupplementOption from "./SupplementOption";
import { TbPill } from "react-icons/tb";
import { getSupplementsProgress } from "utils";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px;
  padding-right: 0px;
  padding-bottom: 60px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px 0;
  padding-left: 20px;
`;

const InfoText = styled.div`
  color: ${(props) => props.theme.colors.text}88;
  font-size: 16px;
`;

const SupplementOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`;

const Supplement = (props: {
  workoutId: string;
  day: DayType;
  supplementIndex: number;
  supplement: SupplementType;
}) => {
  const { workoutId, day, supplementIndex, supplement } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const isTotalCompleted = getSupplementsProgress(day) === 1;

  useEffect(() => {
    setIsCompleted(
      supplement.customOption?.isCompleted ||
        !!supplement.options?.find(
          (supplementOption) => supplementOption.isCompleted
        )
    );
  }, [supplement]);

  useEffect(() => {
    if (isCompleted) {
      setIsOpen(false);
    }
  }, [isCompleted]);

  //
  // RENDER
  //
  return (
    <CheckContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      index={supplementIndex}
      name={supplement.name}
      icon={TbPill}
      isTotalCompleted={isTotalCompleted}
      isCompleted={isCompleted}
    >
      <Content>
        {supplement.text ? (
          <Info>
            <InfoText>{supplement.text}</InfoText>
          </Info>
        ) : null}

        <SupplementOptions>
          {supplement.options?.map((supplementOption, i) => (
            <SupplementOption
              key={i}
              workoutId={workoutId}
              day={day}
              supplementIndex={supplementIndex}
              supplement={supplement}
              supplementIsCompleted={isCompleted}
              supplementOptionIndex={i}
              supplementOption={supplementOption}
              containerIsOpen={isOpen}
            />
          ))}

          <SupplementOption
            workoutId={workoutId}
            day={day}
            supplementIndex={supplementIndex}
            supplement={supplement}
            supplementIsCompleted={isCompleted}
            containerIsOpen={isOpen}
            isCustom
          />
        </SupplementOptions>
      </Content>
    </CheckContainer>
  );
};

export default Supplement;
