import styled, { css } from "styled-components";

import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { PageSlugs } from "pages";
import { useGlobalStorage } from "context/GlobalStorage";

const APP_HEADER_ICON_SIZE = 45;

const Wrapper = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.layout.zIndexHeader + 10};

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          pointer-events: none;
        `}
`;

const Background = styled.div<{ $isOpen: boolean }>`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  background: ${(props) => props.theme.colors.mainDark2}DD;

  transition: all 0.2s ease;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          opacity: 0;
        `}
`;

const BackgroundCircle = styled.div<{ $isOpen: boolean }>`
  --size: 1000px;

  position: absolute;
  top: ${`calc(${APP_HEADER_ICON_SIZE / 2}px + var(--size) / -2)`};
  right: ${`calc(${APP_HEADER_ICON_SIZE / 2}px + var(--size) / -2)`};
  z-index: -1;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  background: ${(props) => props.theme.colors.background};
  border-radius: 50%;

  transition: all 0.2s ease-out;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          --size: 0px;
        `}
`;

const BoxContent = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transition: all 0.2s ease-out;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          opacity: 0;
        `}
`;

const CloseButton = styled.button<{ $isOpen: boolean }>`
  --size: ${`${APP_HEADER_ICON_SIZE / 2}px`};

  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.text}44;
  }

  transition: all 0.2s ease-out;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          transform: scale(0);
        `}
`;

const Content = styled.div<{ $isOpen: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;

  padding: 80px 40px;

  transition: all 0.2s ease-out;

  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          transform: translateX(100px);
        `}
`;

const ButtonCss = css`
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  text-decoration: none;
  text-align: right;

  font-family: inherit;
  font-size: 20px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.text}AA;
`;

const Button = styled.button`
  ${ButtonCss}
`;

const LinkButton = styled(Link)`
  ${ButtonCss}
`;

const AppMenu = (props: { isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose } = props;

  const { fns } = useGlobalStorage();

  return (
    <Wrapper $isOpen={isOpen}>
      <Background $isOpen={isOpen} />
      <BackgroundCircle $isOpen={isOpen} />

      <BoxContent $isOpen={isOpen}>
        <CloseButton $isOpen={isOpen} onClick={onClose}>
          <IoCloseSharp />
        </CloseButton>

        <Content $isOpen={isOpen}>
          <Button
            onClick={() => {
              fns.loadData();
              onClose();
            }}
          >
            Aktualizovat
          </Button>

          <Button
            onClick={() => {
              fns.saveData();
              onClose();
            }}
          >
            Uložit
          </Button>

          <Button
            onClick={() => {
              fns.signOut();
              onClose();
            }}
          >
            Odhlásit
          </Button>

          <LinkButton onClick={onClose} to={PageSlugs.settings}>
            Nastavení
          </LinkButton>
        </Content>
      </BoxContent>
    </Wrapper>
  );
};

export default AppMenu;
