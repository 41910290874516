import { getValidToken, uploadJsonFile } from "utils";

import Button from "components/Button";
import LayoutMain from "layouts/LayoutMain";
import LinkBox from "components/LinkBox";
import { MdFlight } from "react-icons/md";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import { PublicPageSlugs } from "pages-public";
import WORKOUT_PLAN from "assets/WORKOUT_PLAN_JSON_GENERATOR";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";
import { useState } from "react";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;

  background: ${(props) => props.theme.colors.text}04;
  border-radius: 4px;
  padding: 20px;

  box-shadow: 0 4px 12px 0px ${(props) => props.theme.colors.backgroundDark}44;
`;

const SubSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const SectionTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 17px;
  font-weight: 400;
`;

const SectionSubTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 15px;
  font-weight: 400;
`;

const SectionText = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}88;
  font-size: 15px;
  white-space: pre-wrap;

  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: 18px;
  }

  li {
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
  }
`;

const SettingsPage = () => {
  const { data, fns, isOffline } = useGlobalStorage();

  const [isUploading, setIsUploading] = useState(false);

  //
  // UTILS
  //
  const handleUpload = async () => {
    try {
      setIsUploading(true);
      const jsonData = WORKOUT_PLAN;
      const fileName = "example.json";
      const accessToken = await getValidToken();
      const data = await uploadJsonFile(accessToken, fileName, jsonData);
      console.info("Uploaded File:", data);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  };

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <PageHeader title={`Nastavení`} />

      <Content>
        <Section>
          <SectionTitle>Offline Mode</SectionTitle>

          <Button
            onClick={() => fns.setOfflineMode(!isOffline)}
            icon={MdFlight}
            label={`${isOffline ? `Vypnout` : `Zapnout`}`}
          />

          <SubSection style={{ opacity: isOffline ? 1 : 0.5 }}>
            <SectionSubTitle>zapnuto:</SectionSubTitle>
            <SectionText>
              <ul>
                <li>data se ukládají do local storage po každé změně</li>
                <li>žádný auto-reload, není potřeba</li>
              </ul>
            </SectionText>
          </SubSection>

          <SubSection style={{ opacity: !isOffline ? 1 : 0.5 }}>
            <SectionSubTitle>vypnuto:</SectionSubTitle>
            <SectionText>
              <ul>
                <li>data se ukládají do google drive po každé změně</li>
                <li>
                  auto-reload po delší neaktivitě pro zajištění konzistence dat
                  napříč zařízeními
                </li>
              </ul>
            </SectionText>
          </SubSection>
        </Section>

        <Section>
          <SectionTitle>Dev</SectionTitle>

          <LinkBox label={`Json Data Editor`} to={PageSlugs.dataJsonEditor} />

          <Button
            onClick={handleUpload}
            disabled={isUploading}
            label={
              isUploading
                ? "Uploading..."
                : "Upload example JSON file to Google Drive"
            }
          />
        </Section>

        <Section>
          <SectionTitle>Historie</SectionTitle>

          {data?.history?.map((h, i) => (
            <LinkBox
              key={i}
              label={`${h.dates.start} - ${h.dates.end}`}
              to={
                "/" +
                PublicPageSlugs.publicDataPreview.replace(
                  ":googleFileId",
                  h.googleFileId
                )
              }
            />
          ))}
        </Section>
      </Content>
    </LayoutMain>
  );
};

export default SettingsPage;
