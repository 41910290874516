import Button from "components/Button";
import NumberInput from "components/Inputs/NumberInput";
import { RiSaveFill } from "react-icons/ri";
import styled from "styled-components";
import { useState } from "react";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 12px;
  padding-top: 20px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 16px 12px;
`;

const WeightEditor = (props: {
  weight: number;
  onSave: (weight: number) => void;
}) => {
  const { onSave } = props;

  const [weight, setWeight] = useState<number>(props.weight ?? 0);

  //
  // DATA
  //
  const saveChanges = () => {
    onSave?.(weight);
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>
        <NumberInput
          label={`Váha (kg)`}
          value={weight}
          onChange={setWeight}
          step="0,1"
        />
      </Scroll>

      <Footer>
        <Button label="Uložit" icon={RiSaveFill} onClick={saveChanges} />
      </Footer>
    </Wrapper>
  );
};

export default WeightEditor;
